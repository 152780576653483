export const tableColumns = [
    {
        name: 'Sr.No',
        selector: row => row['sr'],
        sortable: true,
        center: false,
    },
    {
        name: 'Full Name',
        selector: row => row['FullName'],
        sortable: true,
        center: true,
    },
    {
        name: 'Email',
        selector: row => row['Email'],
        sortable: true,
        center: true,
    },
    {
        name: 'SSN',
        selector: row => row['SSN'],
        sortable: true,
        center: true,
    },
    {
        name: 'Action',
        selector: row => row['Action'],
        sortable: true,
        center: true,
    }
];