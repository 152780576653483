import React from 'react';
import { Media } from 'reactstrap';
import { Image } from '../../../AbstractElements';
import user1 from '../../../assets/images/user/1.jpg';
import user2 from '../../../assets/images/user/2.png';
import user3 from '../../../assets/images/user/3.jpg';
import user4 from '../../../assets/images/user/3.png';
import user5 from '../../../assets/images/user/4.jpg';
import user6 from '../../../assets/images/user/5.jpg';
import user7 from '../../../assets/images/user/6.jpg';
import user8 from '../../../assets/images/user/7.jpg';
import user9 from '../../../assets/images/user/8.jpg';
import user10 from '../../../assets/images/user/9.jpg';
import user11 from '../../../assets/images/user/10.jpg';
import user12 from '../../../assets/images/user/11.png';
import user13 from '../../../assets/images/user/12.png';

export const dummytabledata = [
    {
        id: 1,
        Title: 'Sun Roof',
        Action: '$3142.00',

    },
   
];

export const tableColumns = [
    {
        name: 'Sr.No',
        selector: row => row['sr'],
        sortable: true,
        center: false,
    },
    
    {
        name: 'Comapany Name',
        selector: row => row['companyName'],
        sortable: true,
        center: false,
    },
  
    {
        name: 'Title',
        selector: row => row['Title'],
        sortable: true,
        center: true,
    },
    {
        name: 'Category',
        selector: row => row['Category'],
        sortable: true,
        center: true,
    },
    {
        name: 'Registration Number',
        selector: row => row['RegistrationNumber'],
        sortable: true,
        center: true,
    },

    {
        name: 'Price Per Day',
        selector: row => row['price'],
        sortable: true,
        center: true,
    },
    {
        name: 'Action',
        selector: row => row['Action'],
        sortable: true,
        center: true,
    }
];