
import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit'
import { fetch2, fetch3 } from '../../Constant/apiMethod.js';



const initialState = {
  loading: false,
  loading2: false,
  error: "",
  success: "",
  user: {},
  totalResveration: [],

  totalVehicle: [],
  totalService: [],
  totalPackage: [],
  totalBooking: [],

}


//  View Resveration
export const LatestResveration = createAsyncThunk(
  'LatestResveration',
  async (body) => {
    const result = await fetch3('admin/latest_resveration', body)
    return result;

  })

    //    Detail  Resveration
export const DetailResveration = createAsyncThunk(
  'DetailResveration',
  async (body) => {
    const result = await fetch3(`admin/detail_resveration/${body}`)
    return result;

  })

  //  Cancel Resveration
export const CancelResveration = createAsyncThunk(
  'CancelResveration',
  async (body) => {
    const result = await fetch3('admin/cancel_resveration', body)
    return result;

  })
      //    Detail  Resveration
export const DeleteResveration = createAsyncThunk(
  'deleteResveration',
  async (body) => {
    const result = await fetch3(`admin/cancel_resveration/${body}`)
    return result;

  })
        //    Detail  Resveration
export const ApproveResveration = createAsyncThunk(
  'ApproveResveration',
  async (body) => {
    const result = await fetch3(`admin/approve_resveration/${body}`)
    return result;

  })


// Inprogress
  export const InprogressResveration = createAsyncThunk(
    'inprogressResveration',
    async (body) => {
      const result = await fetch3('admin/inprogress_resveration', body)
      return result;
    })

    // Inprogress
  export const OnBoardingResveration = createAsyncThunk(
    'OnBoardingResveration',
    async (body) => {
      const result = await fetch3('admin/onboarding_resveration', body)
      return result;
    })


        //    Detail Approve Resveration
   export const DetailAproveResveration = createAsyncThunk(
  'deleteapproveResveration',
  async (body) => {
    const result = await fetch3(`admin/detail_approve_resveration/${body}`)
    return result;

  })

  
        //    Detail Approve Resveration
        export const DetailAprovePackageResveration = createAsyncThunk(
          'detailapprovePackageResveration',
          async (body) => {
            const result = await fetch3(`admin/detail_approve_package_resveration/${body}`)
            return result;
        
          })

// Reducer

const counterSlice = createSlice({
  name: 'resveration',
  initialState,
  reducers: { resetState: () => initialState },
  extraReducers: (builder) => {

 

    // LatestResveration
    builder.addCase(LatestResveration.fulfilled, (state, { payload: { error, success } }) => {
      state.loading = false
      state.totalResveration = success
    })
    builder.addCase(LatestResveration.pending, (state, action) => {
      state.loading = true
    })

       // detail  Resveration
   builder.addCase(DetailResveration.fulfilled, (state, { payload: { error, success } }) => {
    state.loading2 =false
    state.user = success.resveration.user_id
    state.totalVehicle=success.resveration.vehicle_id
    state.totalService=success.resveration.service_id
    state.totalPackage=success.resveration.package_id
      })
      builder.addCase(DetailResveration.pending, (state, action) => {
        state.loading2 = true
      })

 // Cancel Resveration
 builder.addCase(CancelResveration.fulfilled, (state, { payload: { error, success } }) => {
  state.loading = false
  state.totalResveration = success
})

builder.addCase(CancelResveration.pending, (state, action) => {
  state.loading = true
})


  // delete  Resveration
  builder.addCase(DeleteResveration.fulfilled, (state, { payload: { error, success } }) => {
    state.error = error
    state.success=success
      })

        // delete  Resveration
  builder.addCase(ApproveResveration.fulfilled, (state, { payload: { error, success } }) => {
    state.error = error
    state.success=success
      })

       // Inprogress Resveration
 builder.addCase(InprogressResveration.fulfilled, (state, { payload: { error, success } }) => {
  state.totalResveration = success
})

      // Inprogress Resveration
      builder.addCase(OnBoardingResveration.fulfilled, (state, { payload: { error, success } }) => {
        state.totalResveration = success
      })

// Detail Aprove
builder.addCase(DetailAproveResveration.fulfilled, (state, { payload: { error, success } }) => {
  state.user = success.user_id
  state.totalService=success.service_id
  state.totalBooking =success.booking_id
  state.totalPackage=success.package_id
 
    })

    // Detail Aprove package resveration 
builder.addCase(DetailAprovePackageResveration.fulfilled, (state, { payload: { error, success } }) => {
  // state.totalService=success.package_booking_id.package_id.service_id
  state.totalBooking =success
 console.log(';;;',success)
 
    })

  },
})

export const { resetState } = counterSlice.actions

export default counterSlice.reducer;



