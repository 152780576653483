import React from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import { H4 } from '../../AbstractElements';
import SvgIcon from '../Common/Component/SvgIcon';
import CountUp from 'react-countup';
import MyComponent from './constant.js'
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux'


const SmallWidgets = ({ mainClass }) => {
  const result = MyComponent();
  const Data = result[0]


  return (
    <Col xl='6' lg='12' className='box-col-6 xl-50'>
      <Row >
        {Data.map((data, i) => (
          <Col sm='6' key={i} className='m-t-30'>
            <Card className={`small-widget ${mainClass ? mainClass : ''}`}>
              <CardBody className={data.color}>
                <span className='f-light'>{data.title}</span>
                <div className='d-flex align-items-end gap-1'>
                  <H4>
                    {data.total}
                    {/* <CountUp suffix={data.suffix ? data.suffix : ''} prefix={data.prefix ? data.prefix : ''} duration={0} separator=',' end={data.total} /> */}
                  </H4>
                  {/* <span className={`font-${data.color} f-12 f-w-500`}>
            <i className={`icon-arrow-${data.gros < 50 ? 'down' : 'up'}`} />
            <span>
              {data.gros < 50 ? '-' : '+'}
              {data.gros}%
            </span> */}
                  {/* </span> */}
                </div>
                <div className='bg-gradient'>
                  <SvgIcon iconId={data.icon} className='stroke-icon svg-fill' />
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </Col>
  );
};


export default SmallWidgets;
