import React, { Fragment, useCallback, useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../../AbstractElements';
import { dummytabledata, tableColumns } from './Defaultdata';

import { useSelector, useDispatch } from 'react-redux'
import {ApproveResveration, LatestResveration } from '../../../Redux/reducers/ResverationReducers';
import { DeleteResveration } from '../../../Redux/reducers/ResverationReducers';
import { Media,Spinner} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Image } from '../../../AbstractElements';
import user1 from '../../../assets/images/user/1.jpg';
import { resetState } from '../../../Redux/reducers/ResverationReducers';
import {  toast } from "react-toastify";


const DataTableComponent = () => {
    const { totalResveration,error,success,loading } = useSelector(state => state.resveration)
  

    const dispatch = useDispatch();

    const currentUrl = window.location.href;
    const lastPathPart = currentUrl.split('/').pop();



  

    useEffect(() => { 
        if (error) { toast.error(error);  dispatch(resetState()) }
        if (success) { toast.success(success);  dispatch(resetState()) }
        dispatch(LatestResveration())
    }, [error,success])


    const handleDeleteClick = (item) => {
        if (window.confirm("Do you really want to deleted?")) {
            dispatch(DeleteResveration(item));
          } 
        
      };
      
    const handleApproveClick = (item) => {
      
            dispatch(ApproveResveration(item));
          
        
      };

    const tabledata = totalResveration.map((item, index) => ({

        id: index,
        sr :index + 1,
        username:`${item.user_id.firstName} ${item.user_id.lastName}`,
        totalPrice:`${item.totalPrice}`,
        startDate:`${item.startDate} ${item.startTime}`,
        endDate:`${item.endDate} ${item.endTime}` ,
        reason:item.reason ,
        location:item.location ,
        Action: <Media> 
    
       <Link to ={`/detail_resveration/${item._id}`} replace={true} > <i className="icofont icofont-listing-box" style={{fontSize : '20px' , color:'green',paddingLeft:'10px',paddingRight:'10px'}}></i>  </Link>
     
          <i className="icofont icofont-delete"  style={{fontSize : '23px',color:'red' }} onClick={()=>handleDeleteClick(item._id)}></i> 
       <i className="icofont icofont-check" style={{fontSize : '23px',color:'green' ,paddingLeft:'10px'}} onClick={()=>handleApproveClick(item._id)}></i>
       </Media>
,
    }));



  
    return (
        <Fragment>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner animation="border" size="sm" />
          </div>
        ) : (
          <DataTable 
            data={tabledata}
            columns={tableColumns}
            striped={true}
            center={true}
            pagination
          />
        )}
      </Fragment>
    );
    
}
export default DataTableComponent