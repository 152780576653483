import React from 'react';
import { Media } from 'reactstrap';
// import { Image } from '../../../AbstractElements';


export const dummytabledata = [
    {
        id: 1,
        Title: 'Sun Roof',
        Action: '$3142.00',

    },
   
];

export const tableColumns = [
    {
        name: 'Sr.No',
        selector: row => row['sr'],
        sortable: true,
        center: false,
    },
    
    {
        name: 'Comapany Name',
        selector: row => row['companyName'],
        sortable: true,
        center: false,
    },
  
    {
        name: 'Title',
        selector: row => row['Title'],
        sortable: true,
        center: true,
    },
    {
        name: 'Category',
        selector: row => row['Category'],
        sortable: true,
        center: true,
    },
    {
        name: 'Registration Number',
        selector: row => row['RegistrationNumber'],
        sortable: true,
        center: true,
    },

    {
        name: 'Price Per Day',
        selector: row => row['price'],
        sortable: true,
        center: true,
    },
    {
        name: 'Action',
        selector: row => row['Action'],
        sortable: true,
        center: true,
    }
];