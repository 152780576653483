import React, { Fragment, useCallback, useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../../AbstractElements';
import { dummytabledata, tableColumns } from './Defaultdata';

import { useSelector, useDispatch } from 'react-redux'
import { ViewDealer, resetState } from '../../../Redux/reducers/DealerReducers';
import { Link, Navigate } from "react-router-dom";
import { Media } from 'reactstrap';
import { Image } from '../../../AbstractElements';
import user1 from '../../../assets/images/user/1.jpg';
import { IMAGE_PATH } from '../../../Constant/apiMethod';
import { BorderRadius } from '../../../Constant';
const DataTableComponent = () => {
    const { totalDealer } = useSelector(state => state.dealer)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetState())
        dispatch(ViewDealer())
    }, [])



    const tabledata = totalDealer.map((item, index) => ({

        id: index,
        sr :index + 1,
        companyName: <Media className='d-flex'  ><Image attrImage={{ className: 'rounded-circle img-50 me-3 ', src: `${IMAGE_PATH}${item.companyLogo}`, alt: 'Generic placeholder image', style: { width: '50px', height: '50px' } }} />
            <Media body className="align-self-center">
                <div>{item.companyName}</div>
            </Media>
        </Media>,
        FullName : `${item.firstName} ${item.lastName}`,
        Email: `${item.email}`,
        SSN : `${item.ssn}`,
        Action :<Media  > 
             {/* <a href=""><i className="icofont icofont-edit" style={{fontSize : '18px',color:'#214D5A'}}></i>  </a>&nbsp;   &nbsp;  */}
              <Link to={`/detail_dealer/${item._id}`} replace={true} > <i className="icofont icofont-listing-box" style={{fontSize : '18px' , color:'green'}}></i>  </Link></Media>
    }));



    return (
        <Fragment>
    
            <DataTable
                data={tabledata}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
              
            />
        </Fragment>
    )
}
export default DataTableComponent