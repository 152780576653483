
import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit'
import { fetch2, fetch3 } from '../../Constant/apiMethod.js';


const initialState = {
  loading: false,
  error: "",
  success: "",
  vehicle: {},
  totalVehicle: [],

}


// Add Vehicle
export const AddVehicle = createAsyncThunk(
  'AddVehicle',
  async (body) => {
    const result = await fetch2('admin/add_vehicle', body)
    return result;

  })

//  View Dealer
export const ViewVehicle = createAsyncThunk(
  'ViewVehicle',
  async (body) => {
    const result = await fetch3('admin/view_vehicle', body)
    return result;

  })

  //    Detail Vehicle
export const DetailVehicle = createAsyncThunk(
  'DetailVehicle',
  async (body) => {
    const result = await fetch3(`admin/detail_vehicle/${body}`)
    
    console.log('resulr',result)
    return result;

  })


  
  //    Vehicle Featured
export const Featured = createAsyncThunk(
  'featured',
  async (body) => {
    const result = await fetch3(`admin/featured/${body}`)
    console.log('resulr',result)
    return result;

  })

  //  View Featured
export const ViewFeatured = createAsyncThunk(
  'ViewFeatured',
  async (body) => {
    const result = await fetch3('admin/view_featured', body)
    return result;

  })




// Reducer

const counterSlice = createSlice({
  name: 'Vehicle',
  initialState,
  reducers: { resetState: () => initialState },
  extraReducers: (builder) => {


    // Add Vehicle
    builder.addCase(AddVehicle.fulfilled, (state, { payload: { error, success } }) => {
      state.loading = false

      if (error) { state.error = error }
      else { state.success = success }
    })

    builder.addCase(AddVehicle.pending, (state, action) => {
      state.loading = true

    })

    // View Vehicle
    builder.addCase(ViewVehicle.fulfilled, (state, { payload: { error, success } }) => {
      state.totalVehicle = success
    })

     // detail Vehicle
     builder.addCase(DetailVehicle.fulfilled, (state, { payload: { error, success } }) => {
      state.vehicle = success
    })


      // featured
      builder.addCase(Featured.fulfilled, (state, { payload: { error, success } }) => {
        state.success = success
      })

       // View Featured
    builder.addCase(ViewFeatured.fulfilled, (state, { payload: { error, success } }) => {
      state.totalVehicle = success
    })

  },
})

export const { resetState } = counterSlice.actions

export default counterSlice.reducer;



