

export const tableColumns = [
    {
        name: 'Sr.No',
        selector: row => row['sr'],
        sortable: true,
        center: false,
    },
    {
        name: 'Title',
        selector: row => row['Title'],
        sortable: true,
        center: false,
    },
    {
        name: 'Price',
        selector: row => row['price'],
        sortable: true,
        center: false,
    },
  
    {
        name: 'Action',
        selector: row => row['Action'],
        sortable: true,
        center: true,
    }
];