import React, { Fragment, useState, useEffect } from 'react';
import { Col, Card, CardHeader, Row, CardFooter, Modal, ModalBody, ModalHeader, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import CountUp from 'react-countup';
import { H6, Image, LI, UL, Btn } from '../../../../src/AbstractElements';
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import { toast } from "react-toastify";

import * as yup from 'yup'
import user13 from '../../../assets/images/user/12.png';
import { useSelector, useDispatch } from 'react-redux'
import { DetailAproveResveration, DetailResveration } from '../../../Redux/reducers/ResverationReducers'
import { resetState } from '../../../Redux/reducers/ResverationReducers';
import { IMAGE_PATH } from '../../../Constant/apiMethod';

const UserProfile = () => {

  const { loading,  user } = useSelector(state => state.resveration)
  const dispatch = useDispatch();
  const { userId } = useParams();
  const currentUrl = window.location.href;
  const parts = currentUrl.split('/');
  const lastPathPart = parts[parts.length - 2]

  useEffect(() => {



    // if (Object.keys(user).length === 0) {

      if (lastPathPart == 'detail_approve_resveration') { dispatch(DetailAproveResveration(userId)); }
      else { dispatch(DetailResveration(userId)); }

    // }

  }, []);








  return (
    <Fragment>
      <Col sm='12'>
        <Card className='hovercard text-center'>
          <CardHeader className='cardheader'></CardHeader>
          <div className='user-image'>
            <div className='avatar'>
              <Image attrImage={{ className: 'step1', alt: '', src: user.image ? `${IMAGE_PATH}${user.image}` : `${user13}` }} />
            </div>

          </div>
          <div className='info'>
            <Row className='step3' data-intro='This is the your details'>
              <Col sm='6' lg='4' className='order-sm-1 order-xl-0'>
                <Row>
                  <Col md='6'>
                    <div className='ttl-info text-start'>
                      <H6>
                        <i className='fa fa-envelope me-2'></i> Email
                      </H6>
                      <span>{user.email}</span>
                    </div>
                  </Col>
                  <Col md='6'>
                    <div className='ttl-info text-start ttl-xs-mt'>
                      <H6>
                        <i className='fa fa-phone me-2'></i>
                        Contact Us
                      </H6>
                      <span>{user.contact} </span>
                    </div>
                  </Col>

                </Row>
              </Col>
              <Col sm='12' lg='4' className='order-sm-0 order-xl-1'>
                <div className='user-designation'>
                  <div className='title'>
                    <a target='_blank' href='#javascript'>
                      {user.firstName} {user.lastName}
                    </a>
                  </div>
                  <div className='desc mt-2'> {user.ssn}</div>
                </div>
              </Col>
              <Col sm='6' lg='4' className='order-sm-2 order-xl-2'>
                <Row>

                  <Col md='12'>
                    <div className='ttl-info text-start ttl-sm-mb-0'>
                      <H6>
                        <i className='fa fa-location-arrow me-2'></i>
                        Location
                      </H6>
                      <span>{user.street} {user.city} {user.state} {user.zip} {user.country}</span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />

          </div>
        </Card>



      </Col>
    </Fragment>
  );
};

export default UserProfile;
