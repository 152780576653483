import React, { Fragment } from 'react';
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "../Layout/Loader";


import Signin from "../Auth/Signin";
import AppLayout from '../Layout/Layout';
import UsersProfileContain from '../Components/UsersProfile';
import Password from '../Components/Password';


// Dahboard
import Dashboard from '../Components/Dashboard'

import AddDealer from '../Components/Dealership/Add';
import AddVehicle  from '../Components/Vehicle/Add';
import Featured from '../Components/Featured'
import DetailVehicle from '../Components/Vehicle/Detail'
import AddFeature  from '../Components/Feature/Add';
import ViewDealer from '../Components/Dealership/View';
import DetailDealer from '../Components/Dealership/Detail'

import AddDriver from '../Components/Driver/Add';
import ViewDriver from '../Components/Driver/View';
import DetailDriver from '../Components/Driver/Detail';
import Service from  '../Components/Service/Add';
import Vehicle from '../Components/Vehicle/View'
import AddPackage from  '../Components/Package/Add'
import ViewPackage from  '../Components/Package/View'
import DetailPackage from '../Components/Package/Detail'

import PendingResveration from '../Components/Resveration/pending'
import DetailResverationPackage from '../Components/Resveration/Detail/package/Detail'
import DetailCancelResverationPackage from '../Components/Resveration/Detail/package/Detail'
import InprogressResveration from '../Components/Resveration/inprogress'
import CancelResveration from '../Components/Resveration/Cancel'
import OnBoardingResveration from '../Components/Resveration/OnBoarding'


import DetailResveration from '../Components/Resveration/Detail'
import DetailApproveResveration from '../Components/Resveration/DetailApprove'
import DetailApproveResverationPackage from '../Components/Resveration/DetailApprove/package/Detail'


// Service section 
import AddService from  '../Components/Service/Add';
import ViewService from '../Components/Service/View'


// Customer section 
import ViewCustomer from '../Components/Customer/View'
import DetailCustomer from '../Components/Customer/Detail'

import Transaction from '../Components/Transaction'
import Pratice from '../Components/Pratice'

// setup fake backend

const Routers = () => {


  return (
    <BrowserRouter basename={"/"}>
      <Suspense fallback={<Loader />}>
        <Routes>
      

          <Route  path={'/'} element={<Signin />} />
         
          <Fragment >
          <Route element={<AppLayout />} >
            
          <Route  path={'/dashboard'} element={<Dashboard/>} />
          <Route  path={'/partice'} element={<Pratice />} />

          <Route  path={'/profile'} element={<UsersProfileContain />} />
          <Route  path={'/password'} element={<Password />} />

          <Route  path={'/add_dealer'} element={<AddDealer />} />
          <Route  path={'/view_dealer'} element={<ViewDealer/>} />
          <Route  path={'/detail_dealer/:userId'} element={<DetailDealer/>} />

          <Route  path={'/add_vehicle'} element={<AddVehicle />} />
          <Route  path={'/view_vehicle'} element={<Vehicle />} />
          <Route  path={'/detail_vehicle/:userId'} element={<DetailVehicle/>} />
          
          <Route  path={'/featured'} element={<Featured/>} />
       

          <Route  path={'/feature'} element={<AddFeature />} />

          
          <Route  path={'/add_package'} element={<AddPackage />} />
          <Route  path={'/view_package'} element={<ViewPackage />} />
          <Route  path={'/detail_package/:userId'} element={<DetailPackage/>} />
   
           <Route  path={'/add_driver'} element={<AddDriver />} />
           <Route  path={'/view_driver'} element={<ViewDriver />} />
           <Route  path={'/detail_driver/:userId'} element={<DetailDriver/>} />
           <Route  path={'/detail_resveration_driver/:userId'} element={<DetailDriver/>} />
       

          <Route  path={'/pending_resveration'} element={<PendingResveration/>} />
          <Route  path={'/detail_resveration_package/:userId'} element={<DetailResverationPackage/>} />
          <Route  path={'/detail_cancel_resveration_package/:userId'} element={<DetailCancelResverationPackage/>} />
          <Route  path={'/inprogress_resveration'} element={<InprogressResveration/>} />
          <Route  path={'/onboarding_resveration'} element={<OnBoardingResveration/>} />
         
          <Route  path={'/cancel_resveration'} element={<CancelResveration/>} />
       
          <Route  path={'/detail_resveration/:userId'} element={<DetailResveration/>} />
          <Route  path={'/detail_cancel_resveration/:userId'} element={<DetailResveration/>} />
          <Route  path={'/detail_approve_resveration/:userId'} element={<DetailApproveResveration/>} />
          <Route  path={'/detail_approve_resveration_package/:userId'} element={<DetailApproveResverationPackage/>} />
         
          {/* Service Section */}
          <Route  path={'/add_service'} element={<AddService/>} />
          <Route  path={'/view_service'} element={<ViewService/>} />

          {/* Customer Section  */}
          <Route  path={'/view_customer'} element={<ViewCustomer />} /> 
          <Route  path={'/detail_customer/:userId'} element={<DetailCustomer />} /> 



          <Route  path={'/transaction'} element={<Transaction />} /> 
         
          </Route>
          </Fragment>

   
   
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;
