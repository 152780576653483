
import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit'
import { fetch2, fetch3 } from '../../Constant/apiMethod.js';


const initialState = {
  loading: false,
  error: "",
  success: "",
  service: []
}

// Add Service
export const AddService = createAsyncThunk(
  'AddService',
  async (body) => {
    const result = await fetch2('admin/add_service', body)
    return result;

  })

//  View Service
export const ViewService = createAsyncThunk(
  'viewService',
  async (body) => {

    const result = await fetch3('admin/view_service', body)
    return result;

  })

 // Edit Service
 export const editService = createAsyncThunk(
  'editService',
  async (data) => {
    console.log('dddddddddddddddddddddddddbhgggggggggggggggggggggggg',data)
    const result = await fetch2('admin/edit_service', data.formData)
    return result;
  })

// Reducer

const counterSlice = createSlice({
  name: 'AddService',
  initialState,
  reducers: { resetState: () => initialState  },
  extraReducers: (builder) => {

    // Service
    builder.addCase(AddService.fulfilled, (state, { payload: { error, success } }) => {
      state.loading = false
      if (error) { state.error = error }
      else { state.success = success }
    })

    builder.addCase(AddService.pending, (state, action) => {
      state.loading = true

    })

    // View Service
    builder.addCase(ViewService.fulfilled, (state, { payload: { error, success } }) => {
      state.service = success
    })


 
    builder.addCase(editService.pending, (state, action) => {
      state.loading = true
    })
   //   edit Service
   builder.addCase(editService.fulfilled, (state, { payload: { error, success } }) => {
    state.loading = false
    if (error) { state.error = error }
    else { state.success= success }
  })

  },
})

export const { resetState } = counterSlice.actions

export default counterSlice.reducer;



