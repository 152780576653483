
import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit'
import { fetch2, fetch3 } from '../../Constant/apiMethod.js';



const initialState = {
  loading: false,
  packageLoading :false,
  error: "",
  success: "",
  totalVehicle: [],
  totalService: [],
  totalPackage: [],


}

// Add Package
export const AddPackage = createAsyncThunk(
  'AddPackage',
  async (body) => {
    const result = await fetch2('admin/add_package', body)
    return result;

  })

//  View Package
export const ViewPackage = createAsyncThunk(
  'ViewPackage',
  async (body) => {
    const result = await fetch3('admin/view_package', body)
    return result;

  })

//    Detail  Package
export const DetailPackage = createAsyncThunk(
  'DetailPackage',
  async (body) => {
    const result = await fetch3(`admin/detail_package/${body}`)
    return result;
  })

  export const ShowPackageVehicle = createAsyncThunk(
    'ShowPackageVehicle',
    async (body) => {
      const result = await fetch3('admin/show_package_vehicle', body)
      return result;
  
  
    })



// Reducer

const counterSlice = createSlice({
  name: 'AddPackage',
  initialState,
  reducers: { resetState: () => initialState },
  extraReducers: (builder) => {


     // View Vehicle
     builder.addCase(ShowPackageVehicle.fulfilled, (state, { payload: { error, success } }) => {
      console.log(success)
     state.totalVehicle = success

        })

    // Package
    builder.addCase(AddPackage.fulfilled, (state, { payload: { error, success } }) => {
      state.loading = false
      if (error) { state.error = error }
      else { state.success = success }
    })

    builder.addCase(AddPackage.pending, (state, action) => {
      state.loading = true

    })

    

    // View Package
    builder.addCase(ViewPackage.fulfilled, (state, { payload: { error, success } }) => {
      state.totalPackage = success
    })

    // detail  Package
    builder.addCase(DetailPackage.fulfilled, (state, { payload: { error, success } }) => {
      state.packageLoading = false
      state.totalVehicle = success.vehicle_id
      state.totalService = success.service_id

    })

    builder.addCase(DetailPackage.pending, (state, action) => {
      state.packageLoading = true

    })





  },
})

export const { resetState } = counterSlice.actions

export default counterSlice.reducer;



