import React, { Fragment, useCallback, useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../../../AbstractElements';
import { dummytabledata, tableColumns } from './Defaultdata';

import { useSelector, useDispatch } from 'react-redux'

import { Link, Navigate } from "react-router-dom";
import { Media } from 'reactstrap';
import { Image } from '../../../../AbstractElements';
import user1 from '../../../../assets/images/user/1.jpg';
import { IMAGE_PATH } from '../../../../Constant/apiMethod';


import { DetailPackage } from '../../../../Redux/reducers/PackageReducers';

import { useParams } from 'react-router-dom';



const DataTableComponent = () => {
    const { totalVehicle } = useSelector(state => state.package)
    const dispatch = useDispatch();
    
    const { userId } = useParams();
console.log(userId)
    
    useEffect(() => {
        dispatch(DetailPackage(userId));
    }, [])



    const tabledata = totalVehicle.map((item, index) => {
      

        return {
        id: index,
        sr :index + 1,
        companyName: <Media className='d-flex'  ><Image attrImage={{ className: 'rounded-circle img-50 me-3 ', src: `${IMAGE_PATH}${item.image}`, alt: 'Generic placeholder image', style: { width: '50px', height: '50px' } }} />
            <Media body className="align-self-center">
                <div>{item.dealer_id.companyName}</div>
            </Media>
        </Media>,
        Title : `${item.title}`,
        Category : `${item.category}`,
        RegistrationNumber : `${item.registrationNumber}`,
        price : `${item.price}`,
        Action :<Media  > 
  
              <Link to={`/detail_vehicle/${item._id}`} replace={true} > <i className="icofont icofont-listing-box" style={{fontSize : '18px' , color:'green'}}></i>  </Link>
        
                </Media>
    }});



  
    return (
        <Fragment>
      
            <DataTable
                data={tabledata}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
            
               
            />



        </Fragment>
    )
}
export default DataTableComponent