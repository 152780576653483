import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Smallwidgets from './Smallwidgets';
import { Breadcrumbs } from '../../AbstractElements';
import LatestResveration from '../Resveration/pending/index'
import OrderStatusProgressChart from './OrderStatus';
import { useSelector, useDispatch } from 'react-redux'
import { Dashboard } from '../../Redux/reducers/DashboardReducer';


const ChartComponent = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {

    dispatch(Dashboard())
  }, [])


  return (
    <Fragment>
      <Breadcrumbs mainTitle='Dashboard' parent='Gocality' title='Dashboard' />
      <Container fluid={true} className='chart-widget'>

        <Row>
          <OrderStatusProgressChart />
          <Smallwidgets />

          <LatestResveration />
        </Row>
      </Container>
    </Fragment>
  );
};

export default ChartComponent;
