
import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit'
import { fetch2, fetch3 } from '../../Constant/apiMethod.js';


const initialState = {
  loading: false,
  error: "",
  success: "",
  Record: {}
}



// View Dashboard
export const Dashboard = createAsyncThunk(
  'Dashboard',
  async (body) => {

    const result = await fetch3('admin/dashboard', body)
    return result;

  })


// Reducer

const counterSlice = createSlice({
  name: 'ViewDashboard',
  initialState,
  reducers: { resetState: () => initialState  },
  extraReducers: (builder) => {

    //  Dashboard
    builder.addCase(Dashboard.fulfilled, (state, { payload: { error, success } }) => {
      state.Record = success
    })


  },
})

export const { resetState } = counterSlice.actions

export default counterSlice.reducer;



