import React from 'react';
import { Media } from 'reactstrap';
import { Image } from '../../AbstractElements';


export const dummytabledata = [
    {
        id: 1,
        Title: 'Sun Roof',
        Action: '$3142.00',

    },
   
];

export const tableColumns = [
    {
        name: 'Sr.No',
        selector: row => row['sr'],
        sortable: true,
        center: false,
    },
    

  
    {
        name: 'Full Name',
        selector: row => row['FullName'],
        sortable: true,
        center: true,
    },
    {
        name: 'User Email',
        selector: row => row['Email'],
        sortable: true,
        center: true,
    },

    {
        name: 'Transaction ID',
        selector: row => row['ID'],
        sortable: true,
        center: true,
    },
    
    {
        name: 'Amount',
        selector: row => row['amount'],
        sortable: true,
        center: true,
    },
    
    {
        name: 'Action',
        selector: row => row['Action'],
        sortable: true,
        center: true,
    }
];