
import ImageSlider from './ImageSilder';
import ProductDetails from './ProductDetails';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Fragment } from 'react';
import React from 'react';

import { Breadcrumbs } from '../../../AbstractElements';

const ProductPageContain = () => {
  return (
    <Fragment>
      <Breadcrumbs parent='Vehicle' title='Vehicle Detail' mainTitle='Vehicle Detail' />
      <Container fluid={true}>
        <div>
          <Row className='product-page-main p-0'>
            <Col xxl='4' md='6' className='box-col-12'>
              <Card>
                <CardBody>
                
                  <ImageSlider />
                 
                </CardBody>
              </Card>
            </Col>
            <ProductDetails />
          
          </Row>
        </div>
        {/* <Tablet /> */}
      </Container>
    </Fragment>
  );
};
export default ProductPageContain;
