
import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit'
import { fetch2, fetch3 } from '../../Constant/apiMethod.js';



const initialState = {
  loading: false,
  error: "",
  success: "",
  dealer: {},
  totalDealer: [],
  vehicle: {}

}

// Add Dealer
export const AddDealer = createAsyncThunk(
  'AddDealer',
  async (body) => {
    const result = await fetch2('admin/add_dealer', body)
    return result;

  })

//  View Dealer
export const ViewDealer = createAsyncThunk(
  'ViewDealer',
  async (body) => {
    const result = await fetch3('admin/view_dealer', body)
    return result;

  })

// Edit Dealer
export const editDealer = createAsyncThunk(
  'editDealer',

  async (data) => {

    console.log('iddddddddddddddddddddddd', data.id)
    const result = await fetch2(`admin/edit_dealer/${data.id}`, data.formData)
    return result;

  })


//    Detail Dealer
export const DetailDealer = createAsyncThunk(
  'DetailDealer',
  async (body) => {
    const result = await fetch3(`admin/detail_dealer/${body}`)
    return result;

  })


// Reducer

const counterSlice = createSlice({
  name: 'AddDealer',
  initialState,
  reducers: { resetState: () => initialState },
  extraReducers: (builder) => {

    // Dealer
    builder.addCase(AddDealer.fulfilled, (state, { payload: { error, success } }) => {
      state.loading = false
      if (error) { state.error = error }
      else { state.success = success }
    })

    builder.addCase(AddDealer.pending, (state, action) => {
      state.loading = true

    })


    // View Dealer
    builder.addCase(ViewDealer.fulfilled, (state, { payload: { error, success } }) => {
      state.totalDealer = success
    })

    //   edit Dealer
    builder.addCase(editDealer.fulfilled, (state, { payload: { error, success } }) => {
      state.loading = false
      if (error) { state.error = error }
      else { state.success = success }
    })

    builder.addCase(editDealer.pending, (state, action) => {
      state.loading = true
    })

    // detail Dealer
    builder.addCase(DetailDealer.fulfilled, (state, { payload: { error, success } }) => {
      state.dealer = success.dealer
      state.vehicle = success.vehicle
    })



  },
})

export const { resetState } = counterSlice.actions

export default counterSlice.reducer;



