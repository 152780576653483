import React from 'react';
import { Media } from 'reactstrap';
import { Image } from '../../../AbstractElements';


export const dummytabledata = [
    {
        id: 1,
        Title: 'Sun Roof',
        Action: '$3142.00',

    },
   
];

export const tableColumns = [
    {
        name: 'Sr.No',
        selector: row => row['sr'],
        sortable: true,
        center: false,
    },

    {
        name: 'Customer Name',
        selector: row => row['username'],
        sortable: true,
        center: true,
    },

    {
        name: 'Total Price',
        selector: row => row['totalPrice'],
        sortable: true,
        center: false,
    },
  
    {
        name: 'Start Date',
        selector: row => row['startDate'],
        sortable: true,
        center: true,
    },
    {
        name: 'End Date',
        selector: row => row['endDate'],
        sortable: true,
        center: false,
    },
  
    {
        name: 'Reason',
        selector: row => row['reason'],
        sortable: true,
        center: true,
    },
  
    {
        name: 'Location',
        selector: row => row['location'],
        sortable: true,
        center: true,
    },
    
    {
        name: 'Action',
        selector: row => row['Action'],
        sortable: true,
        center: true,
    },
      
 

];