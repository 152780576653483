import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit'
import { fetch2, fetch3 } from '../../Constant/apiMethod.js';



   const initialState = {
   loading: false,
   totalCustomer: [],
   customer :{},
   }



    //  View Customer
    export const ViewCustomer = createAsyncThunk(
    'ViewCustomer',
    async (body) => {
    const result = await fetch3('admin/view_customer', body)
    return result;
    })

    // Detail Customer
   export const DetailCustomer = createAsyncThunk(
   'DetailCustomer',
     async (body) => {
     const result = await fetch3(`admin/detail_customer/${body}`)
     return result;
 
   })


    // Reducer
    const counterSlice = createSlice({
    name: 'Customers',
    initialState,
    reducers: { resetState: () => initialState },


    extraReducers: (builder) => {
    // View Users
    builder.addCase(ViewCustomer.fulfilled, (state, { payload: { error, success } }) => {
    state.totalCustomer = success
    console.log(success)
    })

   // detail Dealer
   builder.addCase(DetailCustomer.fulfilled, (state, { payload: { error, success } }) => {
    state.customer = success
    })

    },

    })


    export const { resetState } = counterSlice.actions
    export default counterSlice.reducer;