
import {configureStore} from '@reduxjs/toolkit'
import AuthReducer from './reducers/AuthReducers'
import DealerReducers from './reducers/DealerReducers';
import VehicleReducers from './reducers/VehicleReducers';
import FeatureReducers from './reducers/FeatureReducers';
import DriverReducers from './reducers/DriverReducers';
import ServiceReducers from './reducers/ServiceReducers';
import ProfileReducers  from './reducers/ProfileReducers';
import ResverationReducers from './reducers/ResverationReducers';
import PackageReducers from './reducers/PackageReducers';
import BookingReducers from './reducers/BookingReducers';
import DashboardReducer from './reducers/DashboardReducer';

import CustomerReducers from './reducers/CustomerReducers';
const store = configureStore({
    reducer : {
        user: AuthReducer,
        dealer:DealerReducers,
        vehicle:VehicleReducers,
        feature:FeatureReducers,
        driver:DriverReducers,
        service:ServiceReducers,
        profile:ProfileReducers,
        resveration:ResverationReducers,
        package:PackageReducers,
        booking:BookingReducers,
        customer:CustomerReducers,
        dashboard :DashboardReducer

     
    }
})



export default store;