import React, { Fragment, useState, useEffect, useContext } from "react";
import { Col, Container, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { Btn, H4, P,Alerts } from "../AbstractElements";
import man from "../assets/images/dashboard/profile.png";
import { ToastContainer, toast } from "react-toastify";
import {useSelector,useDispatch} from 'react-redux'
import { login} from '../Redux/reducers/AuthReducers';
import { resetState } from "../Redux/reducers/AuthReducers";
import { Link ,useNavigate} from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup'
import Toast from "reactstrap";



const Signin = () => {
  const [togglePassword, setTogglePassword] = useState(false);
  let navigate = useNavigate();

  const {loading,error,success} = useSelector(state=>state.user)
  const dispatch = useDispatch();
  const [hasErrorDisplayed, setHasErrorDisplayed] = useState(false);
  

    useEffect(() => {

      if(error){  toast.error(error);      dispatch( resetState())}
      if(success){ navigate('/profile');   dispatch( resetState()) }
  
    },[error,success]);
 
 
    /**********************************   Formik Validation   ****************************************************** */  
    const validationSchema = yup.object({
      email:  yup.string().required('Please enter your email').email(),
      
      });

       const formik = useFormik({
       initialValues: {email: '',password: ''},
       validationSchema: validationSchema,
    
       onSubmit: (values) => {
       dispatch( login( { ...values }))
       },
      });

  return (
    <Fragment>
      
 
      <Container fluid={true} className="p-0 login-page">
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div className="login-main login-tab">


                <Form className="theme-form" onSubmit={formik.handleSubmit}>
                  <H4>Sign In With Simple Login </H4>
                  <P>Enter your email & password to login</P>

                  <FormGroup>
                    <Label className="col-form-label">Email Address </Label>
                    <Input className="form-control" type="email" name="email"  value={formik.values.email} onBlur={formik.handleBlur}  onChange={formik.handleChange}      />
                  <small style={{color : "red"}}>  {formik.touched.email && formik.errors.email}</small>
                  </FormGroup>

                  <FormGroup className="position-relative">
                    <Label className="col-form-label">Password</Label>
                    <div className="position-relative">
                      <Input className="form-control" type={togglePassword ? "text" : "password"}  name="password" value={formik.values.password} onBlur={formik.handleBlur}  onChange={formik.handleChange} />
                      <small style={{color : "red"}}>  {formik.touched.password && formik.errors.password}</small>
                      <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                        <span className={togglePassword ? "" : "show"}></span>
                        
                      </div>
                    </div>
                  </FormGroup>

                  <div className="position-relative form-group mb-0">
                    <div className="checkbox">
                      <Input id="checkbox1" type="checkbox" />
                      <Label className="text-muted" for="checkbox1">
                        Remember Password
                      </Label>
                    </div>
                    <a className="link" href="#javascript">
                      Forgot Password
                    </a>
                    <Btn attrBtn={{ color: "primary", className: "d-block w-100 mt-2" ,type:"submit"}}> 
                     { loading ? <Spinner animation="border" size="sm" />  : 'SignIn' }
                    </Btn>
                    <ToastContainer />
                  </div>
              
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};


export default Signin;
