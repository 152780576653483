import React, { Fragment, useEffect } from 'react';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input,Spinner } from 'reactstrap';
import { H5 ,Btn} from '../../../AbstractElements'
import {CardHeader,CardFooter} from 'reactstrap';
import { Data } from './Data';
import { ToastContainer, toast } from "react-toastify";
import {useSelector,useDispatch} from 'react-redux'
import { AddDealer } from '../../../Redux/reducers/DealerReducers';
import { useFormik } from 'formik';
import { resetState } from '../../../Redux/reducers/DealerReducers';
import * as yup from 'yup'

const BasicFormControlClass = () => {
    
  const {loading,error,success} = useSelector(state=>state.dealer)
  const dispatch = useDispatch();
 
  useEffect(() => {
  if(error){ toast.error(error);  dispatch( resetState()) }
  if(success){ toast.success(success);  dispatch( resetState())}
  },[error,success]);
 
    /**********************************   Formik Validation   ****************************************************** */  
    const validationSchema = yup.object({
        email:  yup.string().required('Please enter your email').email(),
        firstName: yup.string().required(),
        lastName:  yup.string().required(),
        email:     yup.string().email().required(),
        contact :  yup.string().required(),
        ssn:       yup.string().matches(/^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$/ ,'ssn must be in the format XXX-XX-XXXX' ).required(),
        street :   yup.string().required(),
        city:      yup.string().required(),
        state:     yup.string().required(),
        country:   yup.string().required(),
        zip:       yup.number().required(),
        companyName:yup.string().required(),
        });
  
         const formik = useFormik({
         initialValues: {email: '', firstName: '',lastName:'', email:'',contact:'',ssn:'',street:'',city:'',state:'',country:'', zip:'',companyName:'',    image: null, companyLogo: null,},
         validationSchema: validationSchema,
      
         onSubmit: (values) => {
         const formData = new FormData();
            for (let value in values) {
              formData.append(value, values[value]);
            }
    
         dispatch( AddDealer(formData))
         },
        });

    return (
        <Fragment>
               
            <Card>
            <CardHeader > <H5> Add Dealer</H5>   </CardHeader>
                <Form className="form theme-form" onSubmit={formik.handleSubmit} >
                    <CardBody>
                        <Row className='mb-3'>

                        { Data.map((item, index) => ( 
                            <Col  md="4" key={index}>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">{item.title}</Label>
                                    {item.type =='file' ?
                                    <Input className="form-control" name={item.name} type={item.type} onChange= {(e) => formik.setFieldValue(item.name, e.currentTarget.files[0]) } />
                                   :
                                    <Input className="form-control" name={item.name} type={item.type} placeholder={item.placeholder}  value={formik.values[item.name]} onBlur={formik.handleBlur} onChange=  {formik.handleChange } />
                                    }            
                                    <small style={{color : "red"}}>  {formik.touched[item.name] && formik.errors[item.name] }</small>
                                </FormGroup>
                            </Col>

                         ))}

                        </Row>
                      
                    </CardBody>
                    <CardFooter className="text-end">
                <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit" }} >
                { loading ? <Spinner animation="border" size="sm" />  : ' Submit' }</Btn>
               
                 </CardFooter>
                </Form>
            </Card>
            <ToastContainer />
        </Fragment>
    );
};

export default BasicFormControlClass;