import React, { Fragment, useCallback, useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../../../AbstractElements';
import { dummytabledata, tableColumns } from './Defaultdata';

import { useSelector, useDispatch } from 'react-redux'


import { Media, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Image } from '../../../../AbstractElements';
import user1 from '../../../../assets/images/user/1.jpg';
import { IMAGE_PATH } from '../../../../Constant/apiMethod';
import { BorderRadius } from '../../../../Constant';
import { useParams } from 'react-router-dom';
const DataTableComponent = () => {
    const {totalPackage ,loading2 } = useSelector(state => state.resveration)
    const dispatch = useDispatch();

    const { userId } = useParams();
    console.log(userId)
    const currentUrl = window.location.href;
    const parts = currentUrl.split('/');
    const lastPathPart = parts[parts.length - 2];

    const cartIds = [userId, ]; 



    const tabledata = totalPackage.map((item, index) => {
        cartIds.push(item._id)
        return {

        id: index,
        sr :index + 1,
        Title: <Media className='d-flex'  ><Image attrImage={{ className: 'rounded-circle img-50 me-3 ', src: `${IMAGE_PATH}${item.image}`, alt: 'Generic placeholder image', style: { width: '50px', height: '50px' } }} />
            <Media body className="align-self-center">
                <div>{item.title}</div>
            </Media>
        </Media>,
        price: item.price,
        vehicleDiscount :item.vehicleDiscount,
        serviceDiscount:item.serviceDiscount,
        Action:<Media  > 
            
        <Link to={ lastPathPart == 'detail_resveration' ? `/detail_resveration_package/${`${userId}-${item._id}`}` : `/detail_cancel_resveration_package/${`${userId}-${item._id}`}` } replace={true} > <i className="icofont icofont-listing-box" style={{fontSize : '18px' , color:'green'}}></i>  </Link></Media>,
    }});



    
    return (
        <Fragment>
       {loading2 ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner animation="border" size="sm" />
          </div>
        ) : (
            <DataTable
                data={tabledata}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
              
            />
        )}
        </Fragment>
    )
}
export default DataTableComponent