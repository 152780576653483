
import {createSlice,createAsyncThunk,} from '@reduxjs/toolkit'
import { fetch2,fetch3 } from '../../Constant/apiMethod.js';


   const initialState = {
    loading:false,
    error:"",
    success:"",
    totalBooking:[],
    totalpackageBooking:[],  
    totaltransaction:[]
    }

   // Add Service
    export const Booking= createAsyncThunk(
    'booking',
    async (data)=>{
      const result  = await fetch2(`admin/booking/${data.id}`, data.body)
      return  result;
     
    })

//  View Booking
export const ViewBooking = createAsyncThunk(
    'ViewBooking',
    async (body) => {
      const result = await fetch3('admin/view_booking', body)
     
      return result;
  
    })

    // totalpackageBooking
    export const viewpackageBooking = createAsyncThunk(
      'ViewpackageBooking',
      async (body) => {
        const result = await fetch3('admin/view_package_booking', body)
        return result;
      })
  
    //  View Booking
export const packageBooking = createAsyncThunk( 
  'packageBooking',
  async (data) => {
    const result  = await fetch2(`admin/package_booking/${data.id}`, data.body)
    return  result;

  })
//  Transaction
export const ViewTransaction = createAsyncThunk(
  'ViewTransaction',
    async (body) => {
    const result = await fetch3('admin/transaction', body)
    return result;

  })
  
    // Reducer

    const counterSlice = createSlice({
    name: 'Booking',    
    initialState,
    reducers: { resetState: (state) => { return {  ...state,  loading: false,  error: "",  success: ""}} },
    extraReducers: (builder) => {

    // Service
    builder.addCase(Booking.fulfilled,(state,{payload:{error,success}} )=>{
      state.loading = false
    if(error){   state.error =error }
    else {  state.success =success   }
    })

    builder.addCase(Booking.pending,(state,action)=>{
    state.loading = true
    
    })


       // View Booking
       builder.addCase(ViewBooking.fulfilled, (state, { payload: { error, success } }) => {
        state.totalBooking = success
       
      })

      builder.addCase(viewpackageBooking.fulfilled, (state, { payload: { error, success } }) => {
        state.totalpackageBooking = success
       
      })
  // package
  builder.addCase(packageBooking.fulfilled,(state,{payload:{error,success}} )=>{
    state.loading = false
  if(error){   state.error =error }
  else {  state.success =success   }
  })

  builder.addCase(packageBooking.pending,(state,action)=>{
  state.loading = true
  
  })


  
       // Transaction
       builder.addCase(ViewTransaction.fulfilled, (state, { payload: { error, success } }) => {
        state.totaltransaction= success
console.log('sss',success)
      

       
      })

  
  },


})

export const {resetState}  = counterSlice.actions

  export default counterSlice.reducer;



