import React, { Fragment } from 'react';
import { Col, Card, CardHeader, Row } from 'reactstrap';
import { H6, Image, } from '../../../../../src/AbstractElements';
import user13 from '../../../../assets/images/user/12.png';
import { IMAGE_PATH } from '../../../../Constant/apiMethod';



const UserProfile = (prop) => {
    const user = prop.myprop
    console.log(user)
    return (
        <Fragment>
            <Col sm='12'>
                <Card className='hovercard text-center'>
                    <CardHeader className='cardheader'></CardHeader>
                    <div className='user-image'>
                        <div className='avatar'>
                            <Image attrImage={{ className: 'step1', alt: '', src: user.image ? `${IMAGE_PATH}${user.image}` : `${user13}` }} />
                        </div>

                    </div>
                    <div className='info'>
                        <Row className='step3' data-intro='This is the your details'>
                            <Col sm='6' lg='4' className='order-sm-1 order-xl-0'>
                                <Row>
                                    <Col md='6'>
                                        <div className='ttl-info text-start'>
                                            <H6>
                                                <i className='fa fa-envelope me-2'></i> Email
                                            </H6>
                                            <span>{user.email}</span>
                                        </div>
                                    </Col>
                                    <Col md='6'>
                                        <div className='ttl-info text-start ttl-xs-mt'>
                                            <H6>
                                                <i className='fa fa-phone me-2'></i>
                                                Contact Us
                                            </H6>
                                            <span>{user.contact} </span>
                                        </div>
                                    </Col>

                                </Row>
                            </Col>
                            <Col sm='12' lg='4' className='order-sm-0 order-xl-1'>
                                <div className='user-designation'>
                                    <div className='title'>
                                        <a target='_blank' href='#javascript'>
                                            {user.firstName} {user.lastName}
                                        </a>
                                    </div>
                                    <div className='desc mt-2'> {user.ssn}</div>
                                </div>
                            </Col>
                            <Col sm='6' lg='4' className='order-sm-2 order-xl-2'>
                                <Row>

                                    <Col md='12'>
                                        <div className='ttl-info text-start ttl-sm-mb-0'>
                                            <H6>
                                                <i className='fa fa-location-arrow me-2'></i>
                                                Location
                                            </H6>
                                            <span>{user.street} {user.city} {user.state} {user.zip} {user.country}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <hr />

                    </div>
                </Card>



            </Col>
        </Fragment>
    );
};

export default UserProfile;
