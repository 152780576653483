import React, { Fragment, useCallback, useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../AbstractElements';
import { dummytabledata, tableColumns } from './Defaultdata';

import { useSelector, useDispatch } from 'react-redux'
import { ViewFeatured, resetState,Featured } from '../../Redux/reducers/VehicleReducers';
import { Link, Navigate } from "react-router-dom";
import { Media } from 'reactstrap';
import { Image } from '../../AbstractElements';
import user1 from '../../assets/images/user/1.jpg';
import { IMAGE_PATH } from '../../Constant/apiMethod';
import { BorderRadius } from '../../Constant';
import { GlobalHandleContext } from '../../contexts/CartContext';
import { ToastContainer, toast } from "react-toastify";

const DataTableComponent = () => {
    const { totalVehicle,success } = useSelector(state => state.vehicle)
    const dispatch = useDispatch();
    
    const currentUrl = window.location.href;
    const lastPathPart = currentUrl.split('/').pop();

    const Handler = GlobalHandleContext();

  

    useEffect(() => {

        if (success) { toast.success(success) }
        dispatch(resetState())
        dispatch(ViewFeatured())

    }, [success]);


    const tabledata = totalVehicle.map((item, index) => ({

        id: index,
        sr :index + 1,
        companyName: <Media className='d-flex'  ><Image attrImage={{ className: 'rounded-circle img-50 me-3 ', src: `${IMAGE_PATH}${item.image}`, alt: 'Generic placeholder image', style: { width: '50px', height: '50px' } }} />
            <Media body className="align-self-center">
                <div>{item.dealer_id.companyName}</div>
            </Media>
        </Media>,
        Title : `${item.title}`,
        Category : `${item.category}`,
        RegistrationNumber : `${item.registrationNumber}`,
        price : `${item.price}`,
        Action :<Media> 
          
           { lastPathPart == 'featured'  ?  ( <i className="icofont icofont-heart" style={{fontSize : '22px',color:'#FF5733' ,paddingRight:'15px'}} onClick={()=>    dispatch(Featured(item._id))}></i>  )  : (  null ) }
         
         
              <Link to={`/detail_vehicle/${item._id}`} replace={true} > <i className="icofont icofont-listing-box" style={{fontSize : '18px' , color:'green'}}></i>  </Link></Media>
    }));



    
    return (
        <Fragment>
      
            <DataTable
                data={tabledata}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
               
            />
        </Fragment>
    )
}
export default DataTableComponent