import React, { Fragment ,useEffect} from "react";
import { Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../../../src/AbstractElements";
import UserProfile from "../../Common/Component/profile/view_profile";
import { useSelector, useDispatch } from 'react-redux'
import { DetailCustomer } from '../../../Redux/reducers/CustomerReducers';
import { useParams } from 'react-router';
const UsersProfileContain = () => {
  const { customer } = useSelector(state => state.customer)
  const dispatch = useDispatch();
  const { userId } = useParams();

  useEffect(() => {
      dispatch(DetailCustomer(userId))
  }, [])

  


  return (
    <Fragment>
      <Breadcrumbs mainTitle="User Profile" parent="Users" title="User Profile" />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <UserProfile  myprop={customer}/>

          </Row>
        </div>
      </Container>
    </Fragment>
  );
};
export default UsersProfileContain;
