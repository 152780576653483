import React, { Fragment, useEffect } from 'react';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import { Default, Disabled, Checked, Option, CustomCheckbox, CheckboxStates, InlineCheckbox, SuccessState, BrandState, PrimaryState } from '../../../Constant';

import { H5, Btn } from '../../../AbstractElements'
import { CardHeader, CardFooter } from 'reactstrap';
import { years, colors, companies, Data } from './Data';
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from 'react-redux'
import { AddVehicle } from '../../../Redux/reducers/VehicleReducers';
import { useFormik } from 'formik';
import { resetState } from '../../../Redux/reducers/VehicleReducers';
import { ViewFeature } from '../../../Redux/reducers/FeatureReducers';
import { ViewDealer } from '../../../Redux/reducers/DealerReducers';
import * as yup from 'yup'


const BasicFormControlClass = () => {
    const { feature } = useSelector(state => state.feature)
    const { totalDealer } = useSelector(state => state.dealer)

    const { loading, error, success } = useSelector(state => state.vehicle)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ViewFeature())
        dispatch(ViewDealer())
    }, [])


    useEffect(() => {
        if (error) { toast.error(error); dispatch(resetState()) }
        if (success) { toast.success(success); dispatch(resetState()) }
    }, [error, success]);

    /**********************************   Formik Validation   ****************************************************** */
    const validationSchema = yup.object({
        title: yup.string().required(),
        category: yup.string().required(),
        registrationNumber: yup.string().required(),
        color: yup.string().required(),
        make: yup.string().required(),
        modal: yup.number().required(),
        variant: yup.string().required(),
        price: yup.number().required(),
        engineCapacity: yup.number().required(),
        transmission: yup.string().required(),
        dealer_id: yup.string().required(),



    });

    const formik = useFormik({
        initialValues: {
            category: '', registrationNumber: '', color: '', modal: '', make: '', variant: '', price: '', engineCapacity: '', transmission: '',
            dealer_id: null, image: [], feature_id: [],
        },
        validationSchema: validationSchema,

        onSubmit: (values) => {

            const formData = new FormData();

            const selectedImages = [];

            // Loop through the FileList and add each file to the selectedImages array
            for (let i = 0; i < values.image.length; i++) {
                selectedImages.push(values.image[i]);
            }
            // Append each selected image to formData
            for (let i = 0; i < selectedImages.length; i++) {
                formData.append('image', selectedImages[i]);
            }


            for (let value in values) {

                if (value === 'feature_id') {
                    formData.append(value, JSON.stringify(values[value]));
                }
                else {
                    formData.append(value, values[value]);
                }

            }
            console.log(values)

            dispatch(AddVehicle(formData))
        },
    });

    return (
        <Fragment>

            <Card>
                <CardHeader > <H5> Add Vehicle</H5>   </CardHeader>
                <Form className="form theme-form" onSubmit={formik.handleSubmit} >
                    <CardBody>
                        <Row className='mb-3'>


                            <Col md="4" >
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlSelect9">Dealers</Label>
                                    <Input type="select" name="dealer_id" className="form-control digits" defaultValue="1" onBlur={formik.handleBlur} onChange={formik.handleChange}>
                                        <option value=""> Choose...</option>
                                        {totalDealer.map((item, index) => (<option key={index} value={item._id}> {item.firstName}</option>))}
                                    </Input>
                                    <small style={{ color: "red" }}>  {formik.touched.dealer_id && formik.errors.dealer_id}</small>

                                </FormGroup>
                            </Col>

                            <Col md="4" >
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlSelect9">Categories</Label>
                                    <Input type="select" name="category" className="form-control digits" defaultValue="1" onBlur={formik.handleBlur} onChange={formik.handleChange} >
                                        <option value="">Choose...</option>
                                        <option value="Hatchback">Hatchback </option>
                                        <option value="Sedan">Sedan </option>
                                        <option value="SUV"> SUV </option>
                                        <option value="MUV">MUV</option>
                                        <option value="Coupe">Coupe </option>
                                        <option value="Convertible"> Convertible </option>
                                        <option value="Truck">Truck</option>

                                    </Input>
                                    <small style={{ color: "red" }}>  {formik.touched.category && formik.errors.category}</small>
                                </FormGroup>
                            </Col>


                            <Col md="4" >
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlSelect9">Exterior Color</Label>
                                    <Input type="select" name="color" className="form-control digits" defaultValue="1" onBlur={formik.handleBlur} onChange={formik.handleChange}>
                                        <option value="" selected="" disabled="" > Choose...</option>
                                        {colors.map((item, index) => (<option key={index} value={item}> {item}</option>))}
                                    </Input>
                                    <small style={{ color: "red" }}>  {formik.touched.color && formik.errors.color}</small>

                                </FormGroup>
                            </Col>



                            <Col md="4" >
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlSelect9">Modal Year</Label>
                                    <Input type="select" name="modal" className="form-control digits" defaultValue="1" onBlur={formik.handleBlur} onChange={formik.handleChange}>
                                        <option value="" selected="" disabled="" > Choose...</option>
                                        {years.map((item, index) => (<option key={index} value={item}> {item}</option>))}
                                    </Input>
                                    <small style={{ color: "red" }}>  {formik.touched.modal && formik.errors.modal}</small>

                                </FormGroup>
                            </Col>

                            <Col md="4" >
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlSelect9">Make</Label>
                                    <Input type="select" name="make" className="form-control digits" defaultValue="1" onBlur={formik.handleBlur} onChange={formik.handleChange}>
                                        <option value="" selected="" disabled="" > Choose...</option>
                                        {companies.map((item, index) => (<option key={index} value={item}> {item}</option>))}
                                    </Input>
                                    <small style={{ color: "red" }}>  {formik.touched.make && formik.errors.make}</small>

                                </FormGroup>
                            </Col>

                            {Data.map((item, index) => (
                                <Col md="4" key={index}>
                                    <FormGroup>
                                        <Label htmlFor="exampleFormControlInput1">{item.title}</Label>
                                        {item.type == 'file' ?
                                            <Input className="form-control" name={item.name} type={item.type} onChange={(e) => formik.setFieldValue(item.name, e.currentTarget.files)} multiple />
                                            :
                                            <Input className="form-control" name={item.name} type={item.type} placeholder={item.placeholder} value={formik.values[item.name]} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                        }

                                        <small style={{ color: "red" }}>  {formik.touched[item.name] && formik.errors[item.name]}</small>

                                    </FormGroup>

                                </Col>

                            ))}

                            <Col md="4" >
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlSelect9">Transmission</Label>
                                    <Input type="select" name="transmission" className="form-control digits" defaultValue="1" onBlur={formik.handleBlur} onChange={formik.handleChange}>
                                        <option value="">Choose...</option>
                                        <option value="Manual">Manual</option>
                                        <option value="Automatic ">Automatic </option>
                                    </Input>
                                    <small style={{ color: "red" }}>  {formik.touched.transmission && formik.errors.transmission}</small>

                                </FormGroup>
                            </Col>


                            {/* Features start */}
                            <Col sm='12' className='mt-3'>    <H5 attrH5={{ className: 'mb-0' }}>Features</H5>    </Col>


                            {feature.map((item, index) => (
                                <Col sm='2' key={index}>  <div className='checkbox checkbox-dwark'  >
                                    <Input id={`feature-${item._id}`} type='checkbox' name="feature_id" value={item._id} onChange={formik.handleChange} />
                                    <Label for={`feature-${item._id}`}>
                                        {item.title}
                                    </Label>
                                </div>
                                </Col>
                            ))}



                        </Row>
                    </CardBody>
                    <CardFooter className="text-end">
                        <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit" }} >
                            {loading ? <Spinner animation="border" size="sm" /> : ' Submit'}</Btn>

                    </CardFooter>
                </Form>
            </Card>
            <ToastContainer />
        </Fragment>
    );
};

export default BasicFormControlClass;