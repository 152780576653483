import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements'
import BasicFormControlClass from './BasicFormControl';
import Table from '../View'
import DataTableService from '../../Common/Component/Service/DataTableComponent';
import DataTableComponent from './Vehicle/DataTableComponent';
import HeaderCard from '../../Common/Component/HeaderCard';
import { useSelector, useDispatch } from 'react-redux'
import { ViewService } from '../../../Redux/reducers/ServiceReducers';

const BaseInput = () => {
  const { service } = useSelector(state => state.service)
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(ViewService())
  }, [])


  return (
    <Fragment>
      <Breadcrumbs mainTitle='Packages' parent='Add Package' title='Add Packages' subParent='Packages' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <BasicFormControlClass />

          </Col>
        </Row>
      </Container>

      {/* vehicle item */}
      <Card>
        <HeaderCard title="View Vehicle" />
        <CardBody>
          <DataTableComponent />
        </CardBody>
      </Card>


      {/* Service */}
      <Card>
        <HeaderCard title="View Service" />
        <CardBody>
          <DataTableService myprop={service} />
        </CardBody>
      </Card>



      {/* <Table /> */}
    </Fragment>
  );
};

export default BaseInput;
