import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import HeaderCard from '../../Common/Component/HeaderCard';
import DataTableComponent from '../../Common/Component/Service/DataTableComponent';

import { useSelector, useDispatch } from 'react-redux'
import { ViewService } from '../../../Redux/reducers/ServiceReducers';

const DataTables = () => {

  const { service } = useSelector(state => state.service)
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(ViewService())
  }, [])


  return (
    <Fragment>
      <Breadcrumbs mainTitle='Services' parent='View Services' title='view Services' subParent='Services' />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="View Services" />
              <CardBody>
                <DataTableComponent myprop={service} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default DataTables;