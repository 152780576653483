import React, { Fragment, useCallback, useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../../AbstractElements';
import { dummytabledata, tableColumns } from './Defaultdata';

import { useSelector, useDispatch } from 'react-redux'
import {CancelResveration } from '../../../Redux/reducers/ResverationReducers';
import { Media,Spinner} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Image } from '../../../AbstractElements';
import user1 from '../../../assets/images/user/1.jpg';


const DataTableComponent = () => {
    const { totalResveration,loading } = useSelector(state => state.resveration)
  

    const dispatch = useDispatch();



  

    useEffect(() => {
      
        dispatch(CancelResveration())
    }, [])




    const tabledata = totalResveration.map((item, index) => ({

        id: index,
        sr :index + 1,
        username:`${item.user_id.firstName} ${item.user_id.lastName}`,
        totalPrice:`${item.totalPrice}`,
        startDate:`${item.startDate} ${item.startTime}`,
        endDate:`${item.endDate} ${item.endTime}` ,
        reason:item.reason ,
        location:item.location ,
        Action: <Media> 
    
       <Link to ={`/detail_cancel_resveration/${item._id}`} replace={true} > <i className="icofont icofont-listing-box" style={{fontSize : '20px' , color:'green',paddingLeft:'10px',paddingRight:'10px'}}></i>  </Link>

       </Media>
,
    }));



  
    return (
        <Fragment>
      {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner animation="border" size="sm" />
          </div>
        ) : (
            <DataTable
                data={tabledata}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
               
            />
        )}

        </Fragment>
    )
}
export default DataTableComponent