import React, { Fragment, useCallback, useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../../../AbstractElements';
import { dummytabledata, tableColumns } from './Defaultdata';

import { useSelector, useDispatch } from 'react-redux'
import { DetailResveration } from '../../../../Redux/reducers/ResverationReducers';
import { Link, Navigate } from "react-router-dom";
import { Media, Modal, ModalBody, ModalFooter, ModalHeader, Input, Form, FormGroup, Label, Spinner } from 'reactstrap';
import { Image } from '../../../../AbstractElements';
import { IMAGE_PATH } from '../../../../Constant/apiMethod';
import { ViewDriver } from '../../../../Redux/reducers/DriverReducers';
import { useParams } from 'react-router-dom';
import { Booking, ViewBooking } from '../../../../Redux/reducers/BookingReducers';
import { resetState } from '../../../../Redux/reducers/BookingReducers';
import { toast } from "react-toastify";

const DataTableComponent = () => {
    const { totalVehicle, loading2 } = useSelector(state => state.resveration)

    const { totalDriver } = useSelector(state => state.driver)
    const { error, success, totalBooking, loading } = useSelector(state => state.booking)



    const dispatch = useDispatch();

    const { userId } = useParams();



    const [Large, setLarge] = useState(false);
    const LargeModaltoggle = (item) => {
        setvehicleId(item)
        setLarge(!Large)
    };
    const [driver_id, setdriverId] = useState("");

    const [vehicle_id, setvehicleId] = useState("");
    const currentUrl = window.location.href;
    const parts = currentUrl.split('/');
    const lastPathPart = parts[parts.length - 2]

    useEffect(() => {
        if (error) { toast.error(error); LargeModaltoggle(); dispatch(resetState()) }
        if (success) { toast.success(success); LargeModaltoggle(); dispatch(resetState()) }

        dispatch(ViewDriver())

        dispatch(ViewBooking())
    }, [error, success])


    // console.log('bookib',totalbook)
    function onSubmit(e) {
        e.preventDefault();

        const data = { id: userId, body: { driver_id, vehicle_id } }
        dispatch(Booking(data))
        dispatch(ViewBooking())

    }


    const tabledata = totalVehicle.map((item, index) => {
        const hasBooking = totalBooking.some(item1 => item1.vehicle_id === item._id && item1.cart_id === userId);


        return {
            id: index,
            sr: index + 1,
            companyName: <Media className='d-flex'  ><Image attrImage={{ className: 'rounded-circle img-50 me-3 ', src: `${IMAGE_PATH}${item.image}`, alt: 'Generic placeholder image', style: { width: '50px', height: '50px' } }} />
                <Media body className="align-self-center">
                    <div>{item.dealer_id.companyName}</div>
                </Media>
            </Media>,
            Title: `${item.title}`,
            Category: `${item.category}`,
            RegistrationNumber: `${item.registrationNumber}`,
            price: `${item.price}`,
            Action: <Media  >


                <Link to={`/detail_vehicle/${item._id}`} replace={true} > <i className="icofont icofont-listing-box" style={{ fontSize: '18px', color: 'green' }}></i>  </Link>

                {lastPathPart == 'detail_resveration' ? 
                    hasBooking == false ? (

                        <i className="icofont icofont-plus" style={{ fontSize: '18px', paddingLeft: '18px' }} onClick={() => LargeModaltoggle(item._id)}></i>

                    ) :
                        null

                    : null}
            </Media>
        }
    });




    return (
        <Fragment>
            {loading2 ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spinner animation="border" size="sm" />
                </div>
            ) : (
                <DataTable
                    data={tabledata}
                    columns={tableColumns}
                    striped={true}
                    center={true}
                    pagination

                />
            )}

            <Modal isOpen={Large} toggler={LargeModaltoggle} size="md" >
                <ModalHeader toggle={LargeModaltoggle}>
                    Select Driver
                </ModalHeader>
                <Form onSubmit={onSubmit} >
                    <ModalBody >

                        <FormGroup>
                            <Label htmlFor="exampleFormControlSelect9">Drivers</Label>
                            <Input type="select" name="dealer_id" className="form-control digits" defaultValue="1" onChange={(e) => setdriverId(e.target.value)}  >
                                <option value=""> Choose...</option>
                                {totalDriver.map((item, index) => (<option key={index} value={item._id}  > {item.firstName}</option>))}
                            </Input>


                        </FormGroup>
                    </ModalBody>

                    <ModalFooter>
                        <Btn attrBtn={{ color: 'secondary', onClick: LargeModaltoggle }} >Close</Btn> &nbsp;
                        <Btn attrBtn={{ color: "primary", className: "m-r-15", type: 'submit' }} >
                            {loading ? <Spinner animation="border" size="sm" /> : ' Submit'}</Btn>

                    </ModalFooter>
                </Form>
            </Modal>

        </Fragment>
    )
}
export default DataTableComponent