import React, { Fragment, useContext, useEffect, useState } from "react";
import { H3, LI, P, UL } from "../../../AbstractElements";
import { Brand, Availability, AddToCart, BuyNow } from "../../../Constant";

import StarRatings from "./StarRating";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import CartContext from "../../../_helper/Ecommerce/Cart";
import CustomizerContext from "../../../_helper/Customizer";

import { useParams } from 'react-router';
import { useFormik } from 'formik';
import {  toast } from "react-toastify";

import { useSelector, useDispatch } from 'react-redux'
import { DetailVehicle } from '../../../Redux/reducers/VehicleReducers'
import { resetState } from '../../../Redux/reducers/VehicleReducers';


const ProductDetails = () => {

  const { loading, error, success, vehicle } = useSelector(state => state.vehicle)
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [feature ,setfeature] = useState([]);

  useEffect(() => {


    if (error) { toast.error(error); dispatch(resetState()) }
    if (success) { toast.success(success); dispatch(resetState()) }


    if (Object.keys(vehicle).length === 0) {

      dispatch(DetailVehicle(userId));
    
    }

    // Set initial form values when dealer data is available
    if (vehicle) {   setfeature(vehicle.feature_id) }

  }, [vehicle, error, success]);

console.log('vehicle',feature)

  return (
    <Fragment>
      <Col xxl="5" className="box-col-6 order-xxl-0 order-1">
        <Card>
          <CardBody>
            <div className="product-page-details">
              <H3>{vehicle.title}</H3>
            </div>
            <div className="product-price">
             
          $  {vehicle.price}
          
            </div>
        
            <hr />
            
            <div>
              <table className="product-page-width">
                <tbody>
                <tr>
                              <td> <b>Category &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</b></td>
                              <td>{vehicle.category}</td>
                            </tr>
                            <tr>
                              <td> <b>Price Per Day &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                              <td class="txt-danger">$ {vehicle.price}</td>
                            </tr>
                            <tr>
                              <td> <b>Registration Number &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                              <td>{vehicle.registrationNumber}</td>
                            </tr>
                            <tr>
                              <td> <b>Exterior Color &nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                              <td>{vehicle.color}</td>
                            </tr>
                            <tr>
                              <td> <b>Modal Year &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</b></td>
                              <td>{vehicle.modal}</td>
                            </tr>
                            <tr>
                              <td> <b>Make &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                              <td>{vehicle.make}</td>
                            </tr>
                            <tr>
                              <td> <b>variant &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                              <td>{vehicle.variant}</td>
                            </tr>
                            <tr>
                              <td> <b>Engine Capacity * (cc)&nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                              <td>{vehicle.engineCapacity}</td>
                            </tr>
                            <tr>
                              <td> <b>  Transmission &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp; &nbsp;</b></td>
                              <td>{vehicle.transmission}</td>
                            </tr>

                </tbody>
              </table>
            </div>
            <hr />
       
          <Row>
              <Col md="12">
                <h6 className="product-title">Features</h6>
              </Col>

              {feature && feature.map((item,index)=> (
 <Col md="6">
     <i className="icofont icofont-tick-mark txt-success"></i>    {item.title}     
 </Col>

              )) }
             
            </Row> 

            {/* <hr />

            {/* <Row>
              <Col md="4">
                <h6 className="product-title">{"Rate Now"}</h6>
              </Col>
              <Col md="8">
                <StarRatings />
              </Col>
            </Row> */}
            {/* <hr /> */}
         
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};
export default ProductDetails;
