 
import axios from "axios";
const BASE_URL = 'https://algebraic-envoy-397016.oa.r.appspot.com';
export const IMAGE_PATH = 'https://res.cloudinary.com/dx3oigwug/image/upload/v1691515895/'


export const fetch = async (api,body) => {

 const result =  await axios({

     method: 'post',
     url:`${BASE_URL}/${api}` ,
     data:body,
    })

     .then((resp)=>{  return  resp.data;})

     .catch((error)=>{ return   error.response.data  });

       return result
}


// Post method
export const fetch2 = async (api,body) => {
  console.log('apooooooooooooooooo',api)
   const result =  await axios({
  
       method: 'post',
       url:`${BASE_URL}/${api}` ,
       data:body,
       headers: {"auth": localStorage.getItem('token'),  },
      })
  
       .then((resp)=>{  return  resp.data;})
  
       .catch((error)=>{ 
         console.log(error)
         return   error.response.data  });
  
         return result
  }
     
//  Get method
  export const fetch3 = async (api,body) => {
    console.log('bodyyyyyyyyyyy',body)
    const result =  await axios({
   
      
        method: 'get',
        url:`${BASE_URL}/${api}` ,
        data:body,
        headers: {"auth": localStorage.getItem('token'),  },
       })
   
        .then((resp)=>{  return  resp.data;})
   
        .catch((error)=>{ 
          console.log(error)
          return   error.response.data  });
   
          return result
   }


