import React, { Fragment, useCallback, useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';

import { dummytabledata, tableColumns } from './Defaultdata';

import { useSelector, useDispatch } from 'react-redux'
import { ViewService } from '../../../../../../Redux/reducers/ServiceReducers';

import { Media ,Spinner} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Image, Btn, H4 } from '../../../../../../AbstractElements';
import user1 from '../../../../../../assets/images/user/1.jpg';
import { IMAGE_PATH } from '../../../../../../Constant/apiMethod';
import { BorderRadius } from '../../../../../../Constant';
import { useParams } from 'react-router-dom';

import { DetailPackage } from '../../../../../../Redux/reducers/PackageReducers';

const DataTableComponent = () => {
    const { totalService ,packageLoading} = useSelector(state => state.package)
    const { service } = useSelector(state => state.service)
    const dispatch = useDispatch();

    const { userId } = useParams();
    const cartIdArray = userId.split('-');

    useEffect(() => {

        dispatch(DetailPackage(cartIdArray[1]));
      
    }, [])



    const tabledata = totalService.map((item, index) => ({

        id: index,
        sr: index,
        image: <Media className='d-flex'  ><Image attrImage={{ className: 'rounded-circle img-50 me-3 ', src: `${IMAGE_PATH}${item.image}`, alt: 'Generic placeholder image', style: { width: '50px', height: '50px' } }} />

        </Media>,
        title: item.title


    }));




    return (
        <Fragment>
{packageLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner animation="border" size="sm" />
          </div>
        ) : (
            <DataTable
                data={tabledata}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination


            />
        )}
        </Fragment>
    )
}
export default DataTableComponent