import React, { Fragment, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { tableColumns } from './Defaultdata';

import { Media, Modal, ModalBody, ModalFooter, ModalHeader, } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Image, H5, P } from '../../../../AbstractElements';
import { IMAGE_PATH } from '../../../../Constant/apiMethod';
import { GlobalHandleContext } from '../../../../contexts/CartContext';
import EditService from './EditService';

const DataTableComponent = (prop) => {
    const service = prop.myprop
    const currentUrl = window.location.href;
    const lastPathPart = currentUrl.split('/').pop();

    const Handler = GlobalHandleContext();

    const [isModalOpen, setIsModalOpen] = React.useState(new Array(service.length).fill(false));

    const toggleModal = (index) => {
        const updatedIsModalOpen = [...isModalOpen];
        updatedIsModalOpen[index] = !updatedIsModalOpen[index];
        setIsModalOpen(updatedIsModalOpen);
    };


    // edit
    const [editModalOpen, seteditModalOpen] = React.useState(new Array(service.length).fill(false));

    const edittoggleModal = (index) => {
        const updatedIsModalOpen = [...editModalOpen];
        updatedIsModalOpen[index] = !updatedIsModalOpen[index];
        seteditModalOpen(updatedIsModalOpen);
    };


    const tabledata = service.map((item, index) => ({

        id: index,
        sr: index + 1,
        Title: <Media className='d-flex'  ><Image attrImage={{ className: 'rounded-circle img-50 me-3 ', src: `${IMAGE_PATH}${item.image}`, alt: 'Generic placeholder image', style: { width: '50px', height: '50px' } }} />
            <Media body className="align-self-center">
                <div>{item.title}</div>
            </Media>
        </Media>,
        price: `$ ${item.price}`,
        Action: <Media>

            {lastPathPart == 'view_service' ? <i className="icofont icofont-edit" onClick={() => edittoggleModal(index)}  style={{ fontSize: '18px', color: '#214D5A', paddingRight: '15px' }}></i>  : (null)}
            {lastPathPart == 'add_package' ? (<i className="icofont icofont-ui-add" style={{ fontSize: '18px', color: '#214D5A', paddingRight: '15px' }} onClick={() => Handler.serviceItem(item)}></i>) : (null)}
            <a onClick={() => toggleModal(index)}>  <i className="icofont icofont-listing-box" style={{ fontSize: '18px', color: 'green' }} ></i>   </a>


            {/* Detail Modal */}
            <Modal isOpen={isModalOpen[index]} size="md" centered>
                <ModalHeader toggle={() => toggleModal(index)} style={{ paddingBottom: '0px' }}>
                    <div className='profile-title' >
                        <div className='media'>
                            <Image attrImage={{ className: 'img-60 m-0 rounded-circle', alt: '', src: `${IMAGE_PATH}${item.image}`, style: { width: '60px', height: '60px' } }} />
                            <div className='media-body ' style={{ paddingLeft: '15px', paddingTop: '8px' }}>
                                <H5 attrH5={{ className: 'mb-1' }}>{item.title}</H5>
                                <P> $ {item.price}</P>
                            </div>
                        </div>
                    </div>
                </ModalHeader>
                <hr />
                <ModalBody > <div dangerouslySetInnerHTML={{ __html: item.description }} />  </ModalBody>
            </Modal>
            {/* End deatil Modal */}


             {/* edit Modal */}
             <Modal isOpen={editModalOpen[index]} size="lg" centered>
                <ModalHeader toggle={() => edittoggleModal(index)} style={{ paddingBottom: '0px' }}>
                 </ModalHeader>
                 <H5 attrH5={{ className: 'm-l-15' }}>Edit Modal</H5>
                           
                <hr />
                <ModalBody >
                    
                  <EditService  myprop={item} />
                  
               </ModalBody>
            </Modal>
            {/* End edit Modal */}

        </Media>,


    }));




    return (
        <Fragment>

            <DataTable
                data={tabledata}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
            />
        </Fragment>
    )
}
export default DataTableComponent