
import {createSlice,createAsyncThunk,} from '@reduxjs/toolkit'
import { fetch2 ,fetch3} from '../../Constant/apiMethod.js';


   const initialState = {
    loading:false,
    error:"",
    success:"",
    driver:{},
    totalDriver: [],
    }

   // Add driver
    export const AddDriver= createAsyncThunk(
    'AddDriver',
    async (body)=>{
      const result  = await fetch2('admin/add_driver',body)
      return  result;
     
    })

    //  View Driver
    export const ViewDriver= createAsyncThunk(
      'ViewDriver',
      async (body)=>{
     
      const result  = await fetch3('admin/view_driver',body)
        return  result;
       
      })

  // Edit Driver
export const editDriver = createAsyncThunk(
  'editDriver',

  async (data) => {

    console.log('iddddddddddddddddddddddd', data.id)
    const result = await fetch2(`admin/edit_Driver/${data.id}`, data.formData)
    return result;

  })


//    Detail Driver
export const DetailDriver = createAsyncThunk(
  'DetailDriver',
  async (body) => {
    const result = await fetch3(`admin/detail_Driver/${body}`)
    console.log('rrrrrrrrrrrr',result)
    return result;

  })
    // Reducer

    const counterSlice = createSlice({
    name: 'AddDriver',    
    initialState,
    reducers: { resetState: () => initialState },
    extraReducers: (builder) => {

    // Driver
    builder.addCase(AddDriver.fulfilled,(state,{payload:{error,success}} )=>{
      state.loading = false
    if(error){   state.error =error }
    else {  state.success =success   }
    })

    builder.addCase(AddDriver.pending,(state,action)=>{
    state.loading = true
    
    })


    // View Driver
    builder.addCase(ViewDriver.fulfilled,(state,{payload:{error,success}} )=>{
    state.totalDriver= success })


  //   edit Driver
  builder.addCase(editDriver.fulfilled, (state, { payload: { error, success } }) => {
    state.loading = false
    if (error) { state.error = error }
    else { state.success = success }
  })

  builder.addCase(editDriver.pending, (state, action) => {
    state.loading = true
  })

  // detail Driver
  builder.addCase(DetailDriver.fulfilled, (state, { payload: { error, success } }) => {
    state.driver = success
  
  })


  },
})

export const {resetState}  = counterSlice.actions

  export default counterSlice.reducer;



