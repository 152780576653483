import React from 'react';
import Routers from './Route';

import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './_helper/Customizer/CustomizerProvider';
import { CartContext } from './contexts/CartContext';

const App = () => (
  <div className='App'>
    <CartContext >
      <CustomizerProvider>
        <AnimationThemeProvider>
          <Routers />
        </AnimationThemeProvider>
      </CustomizerProvider>
    </CartContext>
  </div>
);

export default App;
