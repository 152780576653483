import React, { Fragment, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { tableColumns } from './Defaultdata';
import { useSelector, useDispatch } from 'react-redux'
import { Link, Navigate } from "react-router-dom";
import { Media } from 'reactstrap';
import { Image } from '../../../AbstractElements';
import { IMAGE_PATH } from '../../../Constant/apiMethod';
import { ViewCustomer } from '../../../Redux/reducers/CustomerReducers';



const DataTableComponent = () => {
    const { totalCustomer } = useSelector(state => state.customer)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ViewCustomer())
    }, [])



    const tabledata = totalCustomer.map((item, index) => ({

        id: index,
        sr: index + 1,
        FullName: <Media className='d-flex'  ><Image attrImage={{ className: 'rounded-circle img-50 me-3 ', src: `${IMAGE_PATH}${item.image}`, alt: 'Generic placeholder image', style: { width: '50px', height: '50px' } }} />
            <Media body className="align-self-center">
                <div> {`${item.firstName} ${item.lastName}`}</div>
            </Media>
        </Media>,

        Email: `${item.email}`,
        SSN: `${item.ssn}`,
        Action: <Media>
               <Link to={`/detail_customer/${item._id}`} replace={true} > <i className="icofont icofont-listing-box" style={{ fontSize: '18px', color: 'green' }}></i>  </Link>
               </Media>
    }));



    return (
        <Fragment>

            <DataTable
                data={tabledata}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination

            />
        </Fragment>
    )
}
export default DataTableComponent