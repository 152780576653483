import React, { useEffect, useRef, useState } from 'react';
import { Image } from '../../../AbstractElements';
import { Fragment } from 'react';
import Slider from 'react-slick';
import { useSelector, useDispatch } from 'react-redux';
import { DetailVehicle } from '../../../Redux/reducers/VehicleReducers';
import { useParams } from 'react-router';
import { resetState } from '../../../Redux/reducers/VehicleReducers';
import { IMAGE_PATH } from '../../../Constant/apiMethod';
import { toast } from "react-toastify";

const ImageSlider = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { vehicle, error, success } = useSelector(state => state.vehicle);


  const images = vehicle?.image || [];

  const [state, setState] = useState({ nav1: null, nav2: null });
  const slider1 = useRef();
  const slider2 = useRef();

  useEffect(() => {
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
    });
  }, []);

  const { nav1, nav2 } = state;

  return (
    <Fragment>
     <Slider asNavFor={nav2} className='product-slider' arrows={false} ref={(slider) => (slider1.current = slider)}>
  {images.map((item, index) => (
    <Image
      attrImage={{
        src: `${IMAGE_PATH}${item}`,
        alt: '',
        className: 'img-fluid w-100',
        style:{height:'19.5rem'}

      }}
      key={index}
    />
  ))}
</Slider>
<Slider asNavFor={nav1} ref={(slider) => (slider2.current = slider)} slidesToShow={images.length >= 3 ? 3 : images.length} swipeToSlide={true} focusOnSelect={true} infinite={true} className='small-slick' >
  {images.map((item, index) => (
    <Image
      attrImage={{
        src: `${IMAGE_PATH}${item}`, // Use a different set of images or thumbnails here
        alt: '',
        className: 'img-fluid item',
        style:{height:'70px'}
      }}
      key={index}
    />
  ))}
</Slider>

    </Fragment>
  );
};

export default ImageSlider;
