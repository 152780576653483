import React, { Fragment, useCallback, useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../../../AbstractElements';
import { dummytabledata, tableColumns } from './Defaultdata';

import { useSelector, useDispatch } from 'react-redux'
import { ShowPackageVehicle } from '../../../../Redux/reducers/PackageReducers';
import { Link, Navigate } from "react-router-dom";
import { Media } from 'reactstrap';
import { Image } from '../../../../AbstractElements';

import { IMAGE_PATH } from '../../../../Constant/apiMethod';
import { BorderRadius } from '../../../../Constant';
import { GlobalHandleContext } from '../../../../contexts/CartContext';
import { ToastContainer, toast } from "react-toastify";

const DataTableComponent = () => {
    const { totalVehicle } = useSelector(state => state.package)
    const dispatch = useDispatch();


    const Handler = GlobalHandleContext();
  

    useEffect(() => {
        dispatch(ShowPackageVehicle())

    }, []);


    const tabledata = totalVehicle.map((item, index) => ({

        id: index,
        sr :index + 1,
        companyName: <Media className='d-flex'  ><Image attrImage={{ className: 'rounded-circle img-50 me-3 ', src: `${IMAGE_PATH}${item.image[0]}`, alt: 'Generic placeholder image', style: { width: '50px', height: '50px' } }} />
            <Media body className="align-self-center">
                <div>{item.dealer_id.companyName}</div>
            </Media>
        </Media>,
        Title : `${item.title}`,
        Category : `${item.category}`,
        RegistrationNumber : `${item.registrationNumber}`,
        price : `${item.price}`,
        Action :<Media> 
          
      
             <i className="icofont icofont-ui-add" style={{fontSize : '18px',color:'#214D5A',paddingRight:'15px'}} onClick={()=>Handler.vehicleItem(item)}></i>   

              <Link to={`/detail_vehicle/${item._id}`} replace={true} > <i className="icofont icofont-listing-box" style={{fontSize : '18px' , color:'green'}}></i>  </Link></Media>
    }));



    
    return (
        <Fragment>
      
            <DataTable
                data={tabledata}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
               
            />
        </Fragment>
    )
}
export default DataTableComponent