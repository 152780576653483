import React, { Fragment, useEffect } from 'react';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Spinner, CardHeader, CardFooter } from 'reactstrap';
import { H5, Btn } from '../../../../AbstractElements';
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from 'react-redux';
import { ViewService, editService } from '../../../../Redux/reducers/ServiceReducers';
import { useFormik } from 'formik';
import { resetState } from '../../../../Redux/reducers/ServiceReducers';
import * as yup from 'yup';
import CKEditors from 'react-ckeditor-component';


const EditService = (prop) => {
    const service = prop.myprop


    const { loading, error, success } = useSelector(state => state.service);
    const dispatch = useDispatch();
    const ref = React.useRef();
    useEffect(() => {
        if (error) { toast.error(error); }
        if (success) { toast.success('Successfully'); dispatch(resetState()) ; dispatch(ViewService()); }

    }, [error, success]);

    /**********************************   Formik Validation   ****************************************************** */
    const validationSchema = yup.object({
        title: yup.string().required(),
        price: yup.number().required(),
    });

    const formik = useFormik({
        initialValues: { title: service.title, price: service.price, image: null, description: service.description, id: service._id },
        validationSchema: validationSchema,

        onSubmit: async (values, { resetForm }) => {


            const formData = new FormData();

            for (let value in values) {
                formData.append(value, values[value]);
            }

            if (values.image != null) {
                const data = { formData: formData }
                dispatch(editService(data))
            }

            else {
                const data = { formData: values }
                dispatch(editService(data))
            }

        }


    })

    //  Clear the file input's value using the ref


    return (
        <div>
            <Form className="form theme-form" onSubmit={formik.handleSubmit} >
                <CardBody>
                    <Row className='mb-3'>

                        <Col xs="4" >
                            <FormGroup>
                                <Label htmlFor="exampleFormControlInput1">Title*</Label>
                                <Input className="form-control" name='title' type='text' placeholder='Enter your title ' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.title} />
                                <small style={{ color: "red" }}>  {formik.touched.title && formik.errors.title}</small>
                            </FormGroup>
                        </Col>

                        <Col xs="4" >
                            <FormGroup>
                                <Label htmlFor="exampleFormControlInput1">price*</Label>
                                <Input className="form-control" name='price' type='number' placeholder='Enter your price ' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.price} />

                                <small style={{ color: "red" }}>  {formik.touched.price && formik.errors.price}</small>
                            </FormGroup>
                        </Col>


                        <Col xs="4" >
                            <FormGroup>
                                <Label htmlFor="exampleFormControlInput1">Image*</Label>
                                <Input className="form-control" name='image' innerRef={ref} type='file' onChange={(e) => formik.setFieldValue('image', e.currentTarget.files[0])} />
                            </FormGroup>
                        </Col>

                        <Col xs="12" className='m-t-30'>
                            <CKEditors
                                name="description"
                                config={{
                                    toolbar: [
                                        {
                                            name: 'basicstyles',
                                            items: ['Undo', 'Redo', '|', 'Bold', 'Italic', 'Strikethrough', '|', 'Link', '|', 'NumberedList', 'BulletedList',],
                                        },
                                        { name: 'styles', items: ['Format'] },
                                        { name: 'list', items: ['NumberedList', 'BulletedList'] },
                                    ],
                                }}
                                data={<p>Loading...</p>} // Set your loading message here
                                content={formik.values.description}
                                events={{
                                    change: (event) => {
                                        formik.setFieldValue('description', event.editor.getData());
                                    }
                                }} />
                        </Col>

                    </Row>
                </CardBody>

                <CardFooter className="text-end">
                    <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit" }} >
                        {loading ? <Spinner animation="border" size="sm" /> : ' Submit'}</Btn>
                </CardFooter>

            </Form>

        </div>
    )
}

export default EditService
