import React, { Fragment, useCallback, useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../AbstractElements';
import { dummytabledata, tableColumns } from './Defaultdata';
import { useSelector, useDispatch } from 'react-redux'
import { ViewTransaction } from '../../Redux/reducers/BookingReducers';
import { Link, Navigate } from "react-router-dom";
import { Media } from 'reactstrap';
import { Image } from '../../AbstractElements';
import user1 from '../../assets/images/user/1.jpg';
import { IMAGE_PATH } from '../../Constant/apiMethod';
import { BorderRadius } from '../../Constant';
const DataTableComponent = () => {
    const { totaltransaction } = useSelector(state => state.booking)
    const dispatch = useDispatch();

    useEffect(() => {
       console.log('sddddddddddddddddd')
        dispatch(ViewTransaction())
    }, [])

console.log(totaltransaction,'ssssssssssssssssssssssssssssss')

    const tabledata = totaltransaction.map((item, index) => ({

        id: index,
        sr :index + 1,
        FullName: 
               
               `${item.user_id.firstName} ${item.user_id.lastName}` 
         
        ,
    
        Email: `${item.user_id.email}`,
        ID : `${item.transactionId}`,
        amount : `$ ${item.orderId.totalPrice}`,
        Action :<Media  > 
             {/* <a href=""><i className="icofont icofont-edit" style={{fontSize : '18px',color:'#214D5A'}}></i>  </a>&nbsp;   &nbsp;  */}
              <Link to={`/detail_approve_resveration/${item.orderId._id}`} replace={true} > <i className="icofont icofont-listing-box" style={{fontSize : '18px' , color:'green'}}></i>  </Link></Media>
    }));




    return (
        <Fragment>
    
            <DataTable
                data={tabledata}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
              
            />
        </Fragment>
    )
}
export default DataTableComponent