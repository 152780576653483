import React, { Fragment, useState, useEffect } from 'react';
import { Col, Card, CardHeader, Row, CardFooter, Modal, ModalBody, ModalHeader, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import CountUp from 'react-countup';
import { H6, Image, LI, UL, Btn } from '../../../../src/AbstractElements';
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import { toast } from "react-toastify";
import { Data } from '../Add/Data';
import * as yup from 'yup'
import user13 from '../../../assets/images/user/12.png';
import { useSelector, useDispatch } from 'react-redux'
import { DetailDealer, editDealer } from '../../../Redux/reducers/DealerReducers'
import { resetState } from '../../../Redux/reducers/DealerReducers';
import { IMAGE_PATH } from '../../../Constant/apiMethod';

const UserProfile = () => {

  const { loading, error, success, dealer } = useSelector(state => state.dealer)
  const dispatch = useDispatch();
  const { userId } = useParams();

  useEffect(() => {


    if (error) { toast.error(error); dispatch(resetState()) }
    if (success) { toast.success(success); dispatch(resetState()); LargeModaltoggle() }


    if (Object.keys(dealer).length === 0) {

      dispatch(DetailDealer(userId));
    }

    // Set initial form values when dealer data is available
    if (dealer) { formik.setValues({ ...dealer }); }

  }, [dealer, error, success]);




  const [Large, setLarge] = useState(false);
  const LargeModaltoggle = () => setLarge(!Large);

  const validationSchema = yup.object({
    email: yup.string().required('Please enter your email').email(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    zip: yup.number(),
  });

  const formik = useFormik({
    initialValues: { email: '', firstName: '', lastName: '', email: '', contact: '', ssn: '', street: '', city: '', state: '', country: '', zip: '', companyName: '', image: null, companyLogo: null, },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      const formData = new FormData();
      for (let value in values) {
        formData.append(value, values[value]);
      }

      if (values.image != null || values.companyLogo != null) {
        const data = { id: userId, formData: formData }
        dispatch(editDealer(data))
      }

      else {
        const data = { id: userId, formData: values }
        dispatch(editDealer(data))
      }


    }


  });
  console.log('emial', formik.errors)

  return (
    <Fragment>
      <Col sm='12'>
        <Card className='hovercard text-center'>
          <CardHeader className='cardheader'></CardHeader>
          <div className='user-image'>
            <div className='avatar'>
              <Image attrImage={{ className: 'step1', alt: '', src: dealer.image ? `${IMAGE_PATH}${dealer.image}` : `${user13}` }} />
            </div>
            <div className='icon-wrapper step2' data-intro='Change Profile image here'>

              <a onClick={LargeModaltoggle}>    <i className='icofont icofont-pencil-alt-5' >  </i> </a>

              {/* Modal start  */}
              <Modal isOpen={Large} size="lg" centered>
                <ModalHeader toggle={LargeModaltoggle}>
                  Edit Dealer
                </ModalHeader>
                <ModalBody >


                  <Form className="form theme-form" onSubmit={formik.handleSubmit} >
                    <Row className='mb-3'>

                      {Data.map((item, index) => (
                        <Col md="4" key={index}>
                          <FormGroup>
                            <Label htmlFor="exampleFormControlInput1">{item.title}</Label>
                            {item.type == 'file' ?
                              <Input className="form-control" name={item.name} type={item.type} onChange={(e) => formik.setFieldValue(item.name, e.currentTarget.files[0])} />
                              :
                              <Input className="form-control" name={item.name} type={item.type} placeholder={item.placeholder} onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values[item.name]} />
                            }
                            <small style={{ color: "red" }}>  {formik.touched[item.name] && formik.errors[item.name]}</small>
                          </FormGroup>
                        </Col>

                      ))}

                    </Row>
                    <CardFooter className="text-end">
                      <Btn attrBtn={{ color: 'secondary', onClick: LargeModaltoggle }} >Close</Btn> &nbsp;
                      <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit" }} >
                        {loading ? <Spinner animation="border" size="sm" /> : ' Submit'}</Btn>
                    </CardFooter>
                  </Form>

                </ModalBody>
              </Modal>

              {/* Modal end */}
            </div>
          </div>
          <div className='info'>
            <Row className='step3' data-intro='This is the your details'>
              <Col sm='6' lg='4' className='order-sm-1 order-xl-0'>
                <Row>
                  <Col md='6'>
                    <div className='ttl-info text-start'>
                      <H6>
                        <i className='fa fa-envelope me-2'></i> Email
                      </H6>
                      <span>{dealer.email}</span>
                    </div>
                  </Col>
                  <Col md='6'>
                    <div className='ttl-info text-start ttl-xs-mt'>
                      <H6>
                        <i className='fa fa-phone me-2'></i>
                        Contact Us
                      </H6>
                      <span>{dealer.contact} </span>
                    </div>
                  </Col>

                </Row>
              </Col>
              <Col sm='12' lg='4' className='order-sm-0 order-xl-1'>
                <div className='user-designation'>
                  <div className='title'>
                    <a target='_blank' href='#javascript'>
                      {dealer.firstName} {dealer.lastName}
                    </a>
                  </div>
                  <div className='desc mt-2'> {dealer.ssn}</div>
                </div>
              </Col>
              <Col sm='6' lg='4' className='order-sm-2 order-xl-2'>
                <Row>

                  <Col md='12'>
                    <div className='ttl-info text-start ttl-sm-mb-0'>
                      <H6>
                        <i className='fa fa-location-arrow me-2'></i>
                        Location
                      </H6>
                      <span>{dealer.street} {dealer.city} {dealer.state} {dealer.zip} {dealer.country}</span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />

          </div>
        </Card>



      </Col>
    </Fragment>
  );
};

export default UserProfile;
