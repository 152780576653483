
import {createSlice,createAsyncThunk,} from '@reduxjs/toolkit'
import { fetch2,fetch3 } from '../../Constant/apiMethod.js';


   const initialState = {
    loading:false,
    error:"",
    success:"",
    feature:[]
    }

   // Add Feature
    export const AddFeature= createAsyncThunk(
    'AddFeature',
    async (body)=>{
      const result  = await fetch2('admin/add_feature',body)
      return  result;
     
    })

     //  View Feature
     export const ViewFeature= createAsyncThunk(
        'viewFeature',
        async (body)=>{
       
        const result  = await fetch3('admin/view_feature',body)
          return  result;
         
        })

  
    // Reducer

    const counterSlice = createSlice({
    name: 'AddFeature',    
    initialState,
    reducers: { resetState: (state) => { return {  ...state,  loading: false,  error: "",  success: ""}} },
    extraReducers: (builder) => {

    // Feature
    builder.addCase(AddFeature.fulfilled,(state,{payload:{error,success}} )=>{
      state.loading = false
    if(error){   state.error =error }
    else {  state.success =success   }
    })

    builder.addCase(AddFeature.pending,(state,action)=>{
    state.loading = true
    
    })
  
      // View Feature
      builder.addCase(ViewFeature.fulfilled,(state,{payload:{error,success}} )=>{
        state.feature= success })


  },
})

export const {resetState}  = counterSlice.actions

  export default counterSlice.reducer;



