

const startYear = new Date().getFullYear() - 50;
const endYear = new Date().getFullYear();
export const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => endYear - index);


export const colors = [
    'Black','White', 'Red', 'Green', 'Blue', 'Yellow', 'Orange', 'Purple', 'Pink', 'Brown', 'Gray','Cyan','Gold','Silver',  
    ];


export const  companies = [ 'Toyota', 'Honda', 'Ford', 'Chevrolet','BMW','Mercedes-Benz','Volkswagen','Nissan','Hyundai','Kia','Audi','Lexus','Volvo','Mazda','Subaru','Tesla','Ferrari','Porsche','Jaguar','Land Rover','Alfa Romeo', 'Chrysler', 'Dodge', 'Jeep', 'Mitsubishi','Suzuki','Peugeot','Renault', 'Citroën', 'Fiat', 'Opel', 'Seat', 'Skoda', 'Acura', 'Buick',  'Cadillac',  'GMC', 'Infiniti','Lincoln','Ram', 'Aston Martin', 'Bentley', 'Lamborghini', 'McLaren', 'Rolls-Royce', 'Smart', 'Mini','Dacia', 'Geely', 'Great Wall Motors', 'Tata Motors', 'Mahindra', 'Proton', 'Perodua', 'SsangYong', 'BYD Auto', 'Chery', 'Dongfeng', 'FAW Group', 'Haval', 'JAC Motors', 'Lifan', 'Zotye',
];

export const Data = [
    {
        title:"Title",
        placeholder: "Enter vehicle title", 
        type:"text",
        name :"title"
    },
    {
        title:"Primary Image",
        placeholder: "", 
        type:"file",
        name :"image"
    },
    {
       title:"Variant",
       placeholder: "7 series*", 
       type:"text",
       name :"variant"
    },
    {
        title:"Registration Number",
        placeholder: "Enter registration number", 
        type:"text",
        name :"registrationNumber"
    },
    {
        title:"Price",
        placeholder: "price per day", 
        type:"number",
        name :"price"
    },
    {
        title:"Engine Capacity * (cc)",
        placeholder: "3000cc*", 
        type:"number",
        name :"engineCapacity"
    },
  
]