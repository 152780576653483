import React, { Fragment, useCallback, useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../../AbstractElements';
import { dummytabledata, tableColumns } from './Defaultdata';

import { useSelector, useDispatch } from 'react-redux'
import { ViewPackage } from '../../../Redux/reducers/PackageReducers';

import { Media } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Image } from '../../../AbstractElements';
import user1 from '../../../assets/images/user/1.jpg';
import { IMAGE_PATH } from '../../../Constant/apiMethod';
import { BorderRadius } from '../../../Constant';
const DataTableComponent = () => {
    const {totalPackage  } = useSelector(state => state.package)
    const dispatch = useDispatch();

 


    useEffect(() => {
        dispatch(ViewPackage())
    }, [])


    const tabledata = totalPackage.map((item, index) => ({

        id: index,
        sr :index + 1,     
        Title: <Media className='d-flex'  ><Image attrImage={{ className: 'rounded-circle img-50 me-3 ', src: `${IMAGE_PATH}${item.image}`, alt: 'Generic placeholder image', style: { width: '50px', height: '50px' } }} />
            <Media body className="align-self-center">
                <div>{item.title}</div>
            </Media>
        </Media>,
        price: item.price,
        vehicleDiscount :item.vehicleDiscount,
        serviceDiscount:item.serviceDiscount,
        Action:<Media  > 
        <Link to={`/detail_package/${item._id}`} replace={true} > <i className="icofont icofont-listing-box" style={{fontSize : '18px' , color:'green'}}></i>  </Link></Media>
 ,
    }));


    return (
        <Fragment>
         
            <DataTable
                data={tabledata}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
               
            />
        </Fragment>
    )
}
export default DataTableComponent