import React, { Fragment, useCallback, useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../../../AbstractElements';
import { dummytabledata, tableColumns } from './Defaultdata';

import { useSelector, useDispatch } from 'react-redux'
import { ViewService } from '../../../../Redux/reducers/ServiceReducers';

import { Media } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Image } from '../../../../AbstractElements';
import user1 from '../../../../assets/images/user/1.jpg';
import { IMAGE_PATH } from '../../../../Constant/apiMethod';
import { BorderRadius } from '../../../../Constant';
import { useParams } from 'react-router-dom';

import { DetailPackage } from '../../../../Redux/reducers/PackageReducers';

const DataTableComponent = () => {
    const { totalService } = useSelector(state => state.package)
    const { service } = useSelector(state => state.service)
    const dispatch = useDispatch();

    const { userId } = useParams();

    useEffect(() => {

        dispatch(DetailPackage(userId));
      
    }, [])



    const tabledata = totalService.map((item, index) => ({

        id: index,
        sr: index,
        image: <Media className='d-flex'  ><Image attrImage={{ className: 'rounded-circle img-50 me-3 ', src: `${IMAGE_PATH}${item.image}`, alt: 'Generic placeholder image', style: { width: '50px', height: '50px' } }} />

        </Media>,
        title: item.title


    }));




    return (
        <Fragment>

            <DataTable
                data={tabledata}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination


            />
        </Fragment>
    )
}
export default DataTableComponent