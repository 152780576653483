import React, { Fragment, useCallback, useState,useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../../AbstractElements';
import { dummytabledata, tableColumns } from './Defaultdata';

import {useSelector,useDispatch} from 'react-redux'
import { ViewFeature} from '../../../Redux/reducers/FeatureReducers';

const DataTableComponent = () => {
    const {feature} = useSelector(state=>state.feature)
  const dispatch = useDispatch();

  useEffect(()=>{
    
    dispatch(ViewFeature())
  },[])

  console.log(feature)
  
  const tabledata = feature.map((item,index) => ({
    id: index,
    sr :index + 1,
    Title: item.title,
    Action:  <i className="icofont icofont-edit" style={{fontSize : '18px',color:'#214D5A',paddingRight:'15px'}}></i>
    ,
  }));
   


 
    return (
        <Fragment>
         
            <DataTable
                data={tabledata}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
               
            />
        </Fragment>
    )
}
export default DataTableComponent