import React, { useState, createContext, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';

const HandleContext = createContext();

const CartContext = ({ children }) => {
  const vechicleItems = [];
  const serviceItems = [];
 
 

  const vehicleItem = (itemid) => {
    const findSameItem = vechicleItems.find(items => items === itemid._id);

    if (findSameItem) {    
    toast.error('Successfully remove Vehicle'); 
    vechicleItems.pop(itemid._id);
   }

    else {
      vechicleItems.push(itemid._id);
      toast.success('Successfully Added Vehicle');
    }
  };

  const serviceItem = (itemid) => {
    const findSameItem = serviceItems.find(items => items === itemid._id);

    if (findSameItem) {    
    toast.error('Successfully remove Service'); 
    serviceItems.pop(itemid._id);
   }

    else {
      serviceItems.push(itemid._id);
      toast.success('Successfully Added Service');
    }
  };


  return (
    <HandleContext.Provider value={{ vechicleItems, vehicleItem,serviceItem,serviceItems }}>
      {children}
    </HandleContext.Provider>
  );
};

const GlobalHandleContext = () => {
  return useContext(HandleContext);
};

export { CartContext, GlobalHandleContext };
