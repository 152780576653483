import React, { Fragment, useCallback, useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../../AbstractElements';
import { dummytabledata, tableColumns } from './Defaultdata';

import { useSelector, useDispatch } from 'react-redux'
import {  resetState,ViewDriver } from '../../../Redux/reducers/DriverReducers';
import { Link, Navigate } from "react-router-dom";
import { Media } from 'reactstrap';
import { Image } from '../../../AbstractElements';
import user1 from '../../../assets/images/user/1.jpg';
import { IMAGE_PATH } from '../../../Constant/apiMethod';
import { BorderRadius } from '../../../Constant';
const DataTableComponent = () => {
    const { totalDriver } = useSelector(state => state.driver)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetState())
        dispatch(ViewDriver())
    }, [])



    const tabledata = totalDriver.map((item, index) => ({

        id: index,
        sr :index + 1,
        FullName: <Media className='d-flex'  ><Image attrImage={{ className: 'rounded-circle img-50 me-3 ', src: `${IMAGE_PATH}${item.image}`, alt: 'Generic placeholder image', style: { width: '50px', height: '50px' } }} />
            <Media body className="align-self-center">
                <div> { `${item.firstName} ${item.lastName}` }</div>
            </Media>
        </Media>,
    
        Email: `${item.email}`,
        SSN : `${item.ssn}`,
        rate : `${item.perHour}`,
        Action :<Media  > 
             {/* <a href=""><i className="icofont icofont-edit" style={{fontSize : '18px',color:'#214D5A'}}></i>  </a>&nbsp;   &nbsp;  */}
              <Link to={`/detail_driver/${item._id}`} replace={true} > <i className="icofont icofont-listing-box" style={{fontSize : '18px' , color:'green'}}></i>  </Link></Media>
    }));



    return (
        <Fragment>
    
            <DataTable
                data={tabledata}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
              
            />
        </Fragment>
    )
}
export default DataTableComponent