import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import HeaderCard from '../../Common/Component/HeaderCard';
import DataTableComponent from './DataTableComponent';

const DataTables = () => {

  const currentUrl = window.location.href;
  const lastPathPart = currentUrl.split('/').pop();

  return (
    <Fragment>
      {lastPathPart == 'pending_resveration' ?
        <Breadcrumbs mainTitle='Resverations' parent='Latest Resverations' title='Latest Resverations' subParent='Resverations' />
  :null}
     <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="Latest Resveration" />
              <CardBody>
                <DataTableComponent />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default DataTables;