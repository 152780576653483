export const MENUITEMS = [
  {
    menutitle: "General",
    menucontent: "Dashboards,Widgets",
    Items: [

       { path: '/dashboard', icon: "icofont-home", title: "Dashboard", type: "link" },
      { path: '/profile', icon: "icofont-ui-user", title: "Profile", type: "link" },
      { path: '/password', icon: "icofont-settings-alt", title: "Setting", type: "link" },
    ],
  },

  {
    menutitle: "Applications",
    menucontent: "Ready to use Apps",
    Items: [
      { path: '/featured', icon: "icofont icofont-heart", title: "Featured Listing", type: "link" },

      {
        title: "Dealerships",
        icon: "icofont icofont-users",
        type: "sub",

        badgetxt: "New",
        active: false,
        children: [
          { path: 'add_dealer', type: "link", title: "Add New" },
          { path: 'view_dealer', type: "link", title: "View Dealerships" },
        ],
      },

      { path: '/feature', icon: "icofont-settings-alt", title: "Vehicle Features", type: "link" },



      {
        title: "Car Listing",
        icon: "icofont icofont-car-alt-3",
        type: "sub",

        badgetxt: "New",
        active: false,
        children: [
          { path: 'add_vehicle', type: "link", title: "Add New" },
          { path: 'view_vehicle', type: "link", title: "View Listing" },
        ],
      },
      {
        title: "Driver Listings",
        icon: "icofont icofont-users-alt-1",
        type: "sub",

        badgetxt: "New",
        active: false,
        children: [
          { path: 'add_driver', type: "link", title: "Add New" },
          { path: 'view_driver', type: "link", title: "View Drivers" },
        ],
      },

      {
        title: "Services",
        icon: "icofont-settings-alt",
        type: "sub",

        badgetxt: "New",
        active: false,
        children: [
          { path: 'add_service', type: "link", title: "Add Service" },
          { path: 'view_service', type: "link", title: "View Service" },
        ],
      },


      {
        title: "Packages",
        icon: "icofont icofont-ebook",
        type: "sub",

        badgetxt: "New",
        active: false,
        children: [
          { path: 'add_package', type: "link", title: "Add New" },
          { path: 'view_package', type: "link", title: "View package" },
        ],
      },



      {
        title: "Resveration",
        icon: "icofont-settings-alt",
        type: "sub",

        badgetxt: "New",
        active: false,
        children: [
          { path: '/pending_resveration', type: "link", title: "Pending" },
          { path: '/inprogress_resveration', type: "link", title: "InProgress" },
          { path: '/onboarding_resveration', type: "link", title: "OnBoarding" },
          { path: '/cancel_resveration', type: "link", title: "Cancelled" },

        ],
      },


      { path: '/view_customer', icon: "icofont icofont-users-social", title: "Customers", type: "link" },

      { path: '/transaction', icon: "icofont icofont-cur-dollar", title: "Transaction", type: "link" },


      { path: '/', icon: "icofont-ui-user", title: "Logout", type: "link" },

    ],
  },


];
