import React, { Fragment, useCallback, useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../../../AbstractElements';
import { dummytabledata, tableColumns } from './Defaultdata';

import { useSelector, useDispatch } from 'react-redux'
import { DetailResveration } from '../../../../Redux/reducers/ResverationReducers';
import { Link, Navigate } from "react-router-dom";
import { Media, Modal, ModalBody, ModalFooter, ModalHeader,CardFooter, Input, Form, FormGroup, Label, Spinner } from 'reactstrap';
import { Image } from '../../../../AbstractElements';
import { IMAGE_PATH } from '../../../../Constant/apiMethod';
import { ViewDriver } from '../../../../Redux/reducers/DriverReducers';
import { useParams } from 'react-router-dom';
import { Booking, ViewBooking } from '../../../../Redux/reducers/BookingReducers';
import { resetState } from '../../../../Redux/reducers/BookingReducers';
import {  toast } from "react-toastify";

const DataTableComponent = () => {
    const [Large, setLarge] = useState(false);
  const LargeModaltoggle = () => setLarge(!Large);

    const { totalBooking} = useSelector(state => state.resveration)
console.log(totalBooking)
    const tabledata = totalBooking.map((item, index) => {
console.log(item)

        return {
        id: index,
        sr :index +1,
        companyName: <Media className='d-flex'  ><Image attrImage={{ className: 'rounded-circle img-50 me-3 ', src: `${IMAGE_PATH}${item.vehicle_id.image}`, alt: 'Generic placeholder image', style: { width: '50px', height: '50px' } }} />
            <Media body className="align-self-center">
                <div>{item.vehicle_id.dealer_id.companyName}</div>
            </Media>
        </Media>,
        Title : `${item.vehicle_id.title}`,
        Category : `${item.vehicle_id.category}`,
        RegistrationNumber : `${item.vehicle_id.registrationNumber}`,
        price : `${item.vehicle_id.price}`,
        Action :<Media  > 
   

            <Link to={`/detail_vehicle/${item.vehicle_id._id}`} replace={true} > <i className="icofont icofont-listing-box" style={{fontSize : '18px' , color:'green'}}></i>  </Link>
            <Link to={`/detail_resveration_driver/${item.driver_id._id}`} replace={true} > <i className="icofont icofont-police" style={{fontSize : '18px' , paddingLeft:'10px'}}></i>  </Link>

            <a onClick={LargeModaltoggle} style={{fontSize : '18px' , paddingLeft:'10px'}}>   <i className="icofont icofont-qr-code"></i> </a>
        

            <Modal isOpen={Large} size="md" centered>
                <ModalHeader toggle={LargeModaltoggle}>
                 
                </ModalHeader>
                <hr />
                <ModalBody >


                <div style={{ textAlign: 'center' }}>
      <Image
        attrImage={{
          className: 'img-150 me-2',
          src: `${IMAGE_PATH}${item.qrCodeImage}`,
          alt: 'Generic placeholder image',
          style: { margin: 'auto' },
        }}
      />
                  
                </div>

                </ModalBody>
              </Modal>

  </Media>
        }
    });



  
    return (
        <Fragment>
           
            <DataTable
                data={tabledata}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
               
            />



        </Fragment>
    )
}
export default DataTableComponent