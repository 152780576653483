import React, { Fragment, useEffect } from 'react';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input,Spinner } from 'reactstrap';
import { H5 ,Btn} from '../../../AbstractElements'
import {CardHeader,CardFooter} from 'reactstrap';
import { Data } from './Data';
import { ToastContainer, toast } from "react-toastify";
import {useSelector,useDispatch} from 'react-redux'
import { AddFeature } from '../../../Redux/reducers/FeatureReducers';
import { useFormik } from 'formik';
import { resetState } from '../../../Redux/reducers/FeatureReducers';
import * as yup from 'yup'

const BasicFormControlClass = () => {
    
  const {loading,error,success} = useSelector(state=>state.feature)
  const dispatch = useDispatch();
  
  
  useEffect(() => {
  if(error){ toast.error(error);  dispatch( resetState()) }
  if(success){ toast.success(success); }

  },[error,success]);
 
    /**********************************   Formik Validation   ****************************************************** */  
    const validationSchema = yup.object({
      
        title: yup.string().required(),
      
        });
  
         const formik = useFormik({
         initialValues: {title: ''},
         validationSchema: validationSchema,
      
         onSubmit: (values) => {
      
    
         dispatch( AddFeature({ ...values }))
         },
        });

    return (
        <Fragment>
               
            <Card>
            <CardHeader > <H5> Add Vehicle Features</H5>   </CardHeader>
                <Form className="form theme-form" onSubmit={formik.handleSubmit} >
                    <CardBody>
                        <Row className='mb-3'>

                            <Col  xs="8" >
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Feature Title*</Label>
                                <Input className="form-control" name='title' type='text' placeholder='Enter your title name'  onBlur={formik.handleBlur} onChange=  {formik.handleChange } />
                                              
                                    <small style={{color : "red"}}>  {formik.touched.title && formik.errors.title }</small>
                                </FormGroup>
                            </Col>

                            <Col  xs="4" className="text-end" style={{marginTop:'30px'}}>
                            <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit" }} >
                { loading ? <Spinner animation="border" size="sm" />  : ' Submit' }</Btn>
                </Col>


                        </Row>
                      
                    </CardBody>
                
                </Form>
            </Card>
            <ToastContainer />
        </Fragment>
    );
};

export default BasicFormControlClass;