export const Data = [
    {
       title:"Title",
       placeholder: "Enter your Title", 
       type:"text",
       name :"title"
    },
    {
        title:"image",
        placeholder: "", 
        type:"file",
        name :"image"
     },

    {
        title:"Vehicle Discount",
        placeholder: "Enter your Vehicle Discount", 
        type:"number",
        name :"vehicleDiscount"
    },
     {
        title:"Service Discount",
        placeholder: "Enter your serviceDiscount", 
        type:"number",
        name :"serviceDiscount"
     },
   
      
]