import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import HeaderCard from '../../Common/Component/HeaderCard';
import DataTableComponent from './vehicle/DataTableComponent';
import Service from './service'
const DataTables = () => {

  return (
    <Fragment>
        <Breadcrumbs mainTitle='Packages' parent='Detail Package' title='Detail Package' subParent='Packages' />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="View Vehicles" />
              <CardBody>
                <DataTableComponent />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      
      <Service />
    </Fragment>
  );

};

export default DataTables;