import React, { Fragment } from "react";
import { Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../../../src/AbstractElements";
import UserProfile from "../Detail/UserProfile";
import DataTableComponent from "./vehicle";
import Service from '../Detail/service'
import Package from './package'

const UsersProfileContain = () => {
  return (
    <Fragment>
        <Breadcrumbs mainTitle='Resverations' parent='Detail Resverations' title='Deatil Resverations' subParent='Resverations' />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <UserProfile  />
       
      
          </Row>
        </div>
      </Container>

      <DataTableComponent/>

      <Service />
      <Package />
    </Fragment>
  );
};
export default UsersProfileContain;
