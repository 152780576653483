
import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit'
import { fetch2, fetch3 } from '../../Constant/apiMethod.js';


const initialState = {
  loading: false,
  error: "",
  success: "",
  admin: {}
}



//  View Dealer
export const ViewProfile = createAsyncThunk(
  'profile',
  async (body) => {

    const result = await fetch3('admin/profile', body)
    return result;

  })

export const editProfile = createAsyncThunk(
  'editprofile',
  async (body) => {
    const result = await fetch2('admin/edit_profile', body)
    return result;

  })


// Reducer

const counterSlice = createSlice({
  name: 'Profile',
  initialState,
  reducers: { resetState: () => initialState },
  extraReducers: (builder) => {

    //   edit profile
    builder.addCase(editProfile.fulfilled, (state, { payload: { error, success } }) => {
      state.loading = false
      if (error) { state.error = error }
      else { state.success = success }
    })

    builder.addCase(editProfile.pending, (state, action) => {
      state.loading = true

    })

    // View Profile
    builder.addCase(ViewProfile.fulfilled, (state, { payload: { error, success } }) => {
      state.admin = success
    })



  },
})

export const { resetState } = counterSlice.actions

export default counterSlice.reducer;



