import React, { Fragment, useEffect } from 'react';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input,Spinner } from 'reactstrap';
import { H5 ,Btn} from '../../../AbstractElements'
import {CardHeader,CardFooter} from 'reactstrap';

import { ToastContainer, toast } from "react-toastify";
import {useSelector,useDispatch} from 'react-redux'
import { AddPackage, ShowPackageVehicle } from '../../../Redux/reducers/PackageReducers';
import { useFormik } from 'formik';
import { resetState } from '../../../Redux/reducers/ServiceReducers';
import * as yup from 'yup'
import {Data} from './Data'
import { GlobalHandleContext } from '../../../contexts/CartContext';
const BasicFormControlClass = () => {
    
  const {loading,error,success} = useSelector(state=>state.package)
  const dispatch = useDispatch();

  const Handler = GlobalHandleContext();
  
  useEffect(() => {
  if(error){ toast.error(error);  dispatch( resetState()) }
  if(success){ toast.success(success);  dispatch( resetState())
 dispatch(ShowPackageVehicle());
}

  },[error,success]);


 
    /**********************************   Formik Validation   ****************************************************** */  
    const validationSchema = yup.object({
      
        title: yup.string().required(),
        vehicleDiscount: yup.number().required(),
        serviceDiscount: yup.number().required(),
        });
  
         const formik = useFormik({
         initialValues: {title: '', vehicleDiscount:'',serviceDiscount:'',image:null},
         validationSchema: validationSchema,
      
         onSubmit: (values) => {
            console.log('hamndle',Handler.vechicleItems)
            const formData = new FormData();
            for (let vehicleId of Handler.vechicleItems) {
                formData.append('vehicle_id', vehicleId);
              }
              
              for (let serviceId of Handler.serviceItems) {
                formData.append('service_id', serviceId);
              }
              
            for (let value in values) {
              formData.append(value, values[value]);
            }  
         dispatch(AddPackage(formData))
         },
        });

    return (
        <Fragment>
               
            <Card>
            <CardHeader > <H5> Add Package</H5>   </CardHeader>
                <Form className="form theme-form" onSubmit={formik.handleSubmit} >
                    <CardBody>
                        <Row className='mb-3'>

                        { Data.map((item, index) => ( 
                            <Col  md="4" key={index}>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">{item.title}</Label>
                                    {item.type =='file' ?
                                    <Input className="form-control" name={item.name} type={item.type} onChange= {(e) => formik.setFieldValue(item.name, e.currentTarget.files[0]) }  required/>
                                   :
                                    <Input className="form-control" name={item.name} type={item.type} placeholder={item.placeholder}  value={formik.values[item.name]} onBlur={formik.handleBlur} onChange=  {formik.handleChange } />
                                    }            
                                    <small style={{color : "red"}}>  {formik.touched[item.name] && formik.errors[item.name] }</small>
                                </FormGroup>
                            </Col>

                         ))}



                        </Row>
                      
                    </CardBody>
                    <CardFooter className="text-end">
                        <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit" }} >
                            {loading ? <Spinner animation="border" size="sm" /> : ' Submit'}</Btn>

                    </CardFooter>
                
                </Form>
            </Card>
            <ToastContainer />
        </Fragment>
    );
};

export default BasicFormControlClass;